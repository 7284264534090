import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TouristSuccessComponent } from './touristSuccess.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TouristSuccessComponent],
  exports: [TouristSuccessComponent]
})
export class TouristSuccessModule { }
