
  <!-- Step 1 -->
  <div class="flinkit-section-bg" *ngIf="activeGame">
    <div class="flinkit-section-bg">
      <div class="flinkit-new-page-content-section">
        <div class="upper" [style.background-color]="bgColor">
          <div class="content">
            <!-- Logo container -->
            <div class="text-center text-white">
<!--               <img [src]="activeGame && activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/museums/logo_b.png'" class="img-fluid-80" alt="">
 -->              <img [src]="activeGame && activeGame.isCustomBranding ? activeGame.customBranding.cover : (activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/logo_b.png')" width="140" alt="">

            </div>
            <!-- End of Logo container -->
            <div class="row mt-3 text-center">
              <div class="col-12" data-aos="fade-up" data-aos-delay="100">
                <h6 class="flinkit-event-text">{{activeGame.name}}</h6>
              </div>
            </div>
          </div>
          <!-- Divider-->
          <div class="flinkit-divider">
            <img src="./assets/images/divider.png" class="img-fluid" alt="">
          </div>
          <!-- End of Divider-->
        </div>
  
        <div class="bottom d-flex align-items-center justify-content-center">
          <div class="w-75">
            <!-- Step 1 -->
             <div class="form-group"  data-aos="fade-left" data-aos-delay="200" *ngIf="pageConfig.loginStep === 0">
              😎 &nbsp;<label class="fi-text-bold">{{localeService.getTrans("Player name/team name")}}</label>
              <input type="text" [(ngModel)]="playerObj.teamName" autofocus [maxlength]="20" placeholder="Player name" i18n-placeholder class="form-control fi-form-control">
              <small class="flinkit-text-small fi-text-bold text-danger" *ngIf="playerObj &&  playerObj.teamName && playerObj.teamName.length < 5 ">{{5-playerObj.teamName.length}} <span i18n>characters more</span></small>
              <div class="form-group has-error" *ngIf="error">
                <small class="help-block bg-danger-alt text-danger">{{error}}</small>
              </div>
              <div class="form-group">
                <small class="help-block bg-danger-alt"><i class="las la-info-circle "></i><span i18n>This will be your player name in the game</span></small>
              </div>
              <button class="flinkint-btn primary btn-block mt-3" [style.border-color]="bgColor" [style.background-color]="bgColor" [disabled]="!playerObj.teamName || playerObj.teamName.length < 5 " (click)="pageConfig.loginStep = 1"  data-aos="fade-left" i18n>Next</button>
              <button class="flinkint-btn primary btn-block mt-1" [style.border-color]="bgColor" [style.background-color]="bgColor" *ngIf="reconnectActive" (click)="reconnectToGame()">{{locales.reconnect}}</button>

            </div>
            <!-- End of Step 1 -->
            <!-- Step 1 -->
             <div class="form-group"  data-aos="fade-left"  *ngIf="pageConfig.loginStep === 1">
              <h4 class="fi-text-bold text-center"><span i18n>Hello </span> <span class="fi-text-primary">{{playerObj.teamName}}! </span> <span i18n> Set an avatar!</span></h4>
              <swiper [config]="swiperConfig"  #usefulSwiper class="mt-3">
                <div class="swiper-wrapper d-flex align-items-center">
                  <div class="swiper-slide emojis" *ngFor="let emoji of emojiService.getEmojis(); let i = index" (click)="setAvatarIndex(i)"><img [src]="emoji.icon" alt=""></div>
                </div>
              </swiper>
              <div class="row mt-2">
                <div class="col-12 text-center">
                  <div class="arrow-up ml-auto mr-auto"></div>
                </div>
              </div>
              <button class="flinkint-btn primary btn-block mt-3" [style.border-color]="bgColor" [style.background-color]="bgColor" [disabled]="pageConfig.loaders.joinGame" (click)="setAvatar(); joinGame()">
                <span *ngIf="pageConfig.loaders.joinGame else loaderBlock"><i class="las la-circle-notch la-2x la-spin"></i></span>
                <ng-template #loaderBlock> <span i18n>Start game</span></ng-template>
              </button>
              <div class="row mt-1 text-center">
                <div class="col-12">
                  <button class="flinkint-btn primary outline btn-block mt-1" [style.color]="bgColor" [style.border-color]="bgColor" (click)="pageConfig.loginStep = 0" i18n>Back</button>
                </div>
              </div>
            </div>
            <!-- End of Step 1 -->
            <div class="row text-center">
              <div class="col-12 text-center fi-text-bold">
                <!-- <img [src]="activeGame.isCustomBranding ? activeGame.customBranding.cover : (activeGame.partner.isDigitalMuseum ? activeGame.partner.logo : './assets/images/pbe.png')" width="90" alt=""> -->

                 <img [src]="activeGame.partner.isWhiteLabel ? activeGame.partner.logo  :'./assets/images/pbe.png'" width="90" alt="">

<!--                 <img src="./assets/images/pbe.png" width="90" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
