import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import { EmojiServiceService } from '../services/emoji-service.service';
import { StorageService } from '../services/storage.service';
import { LocaleService } from '../services/locale.service';
import { GeoService } from '../services/geo.service';
@Component({
  selector: 'app-endGame',
  templateUrl: './endGame.component.html',
  styleUrls: ['./endGame.component.scss']
})
export class EndGameComponent implements OnInit {
  players = [];
  bgColor;
  loaders = {
    refresh: false
  }
  groupId;
  activeGame;
  pageConfig = {
    gameId: null,
    playerId: null,
    activeTab: 'leaderboard'
  };
  locales = {
    hallOfFame: this.localeService.getTrans('Hall of fame'),
    congrats: this.localeService.getTrans('Congrats! The game has ended!')
  }
  constructor( 
    private route: ActivatedRoute,
    private router: Router, 
    private http: HttpClient,
    public localeService: LocaleService,
    private geoService: GeoService,
    public emojiService: EmojiServiceService, private storageService: StorageService) {
      if (this.route.snapshot.paramMap.get('g') && this.route.snapshot.paramMap.get('p')) {
        this.storageService.removeStorage('fl_storage');
        this.pageConfig.gameId = this.route.snapshot.paramMap.get('g');
        this.pageConfig.playerId = this.route.snapshot.paramMap.get('p');
        this.groupId = this.route.snapshot.paramMap.get('groupId');
        this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'));
      } else{
        this.navigateBack();
      } 
  }
  refresh(){
    if(this.pageConfig.activeTab === 'leaderboard'){
      this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'));
    } else{
      this.getHOF(this.route.snapshot.paramMap.get('g'));
    }
  }

  setTab(activeTab){
    this.pageConfig.activeTab = activeTab;
    if(this.pageConfig.activeTab === 'leaderboard'){
      this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'));
    } else{
      this.getHOF(this.route.snapshot.paramMap.get('g'));
    }
  }

   share(){
    if (navigator.share) {
      navigator.share({
        title: 'Flinkit! Outdoor! | Leaderboard',
        url: window.location.href
      }).then(() => {
        
      })
      .catch(console.error);
    } else {
      console.log('Share is not supported')
    }
  }

  getHOF(gameId){
    this.loaders.refresh = true;
    return this.http.post(environment.baseUrl + 'games/getHofByGame', {gameId, groupId: this.route.snapshot.paramMap.get('groupId')} ).subscribe((data: any) => {
      this.loaders.refresh = false;
      if(data.success){
         this.players = _.orderBy(data.data, ['points'], ['desc']);
         console.log(this.players)
      } else{
        this.navigateBack()
      }
    }); 
  }
  getGame(gameId, player){
    return this.http.post(environment.baseUrl + 'games/getGameRes', {groupId: this.groupId} ).subscribe((data: any) => {
     if(data.success){
      console.log(data)
        this.activeGame = data.game;
        this.activeGame.partner = data.partner;
        this.players = _.orderBy(data.players, ['points'], ['desc']);
        this.bgColor = this.activeGame.partner ? ((this.activeGame.tourId.isCustomBranding && this.activeGame.tourId.customBranding.color) ? this.activeGame.tourId.customBranding.color : (this.activeGame.partner.color ? this.activeGame.partner.color : null)) : null;

     } else{
       this.navigateBack()
     }
   }); 
 }


  navigateBack() {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.storageService.removeStorage('fl-game-details');
    setTimeout(()=>{
      this.geoService.dropConfetti();
    },1000)
  }

}
