<div class="flinkit-full-bg white" *ngIf="game && game.tourId">
  <div class="row h-100 mr-0 ml-0 fi-walkthrough-container">
    <div class="col-12 d-flex align-items-center justify-content-center h-100 text-center">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center">
        <img [src]="game.tourId.isCustomBranding ? game.tourId.customBranding.cover : (game.partner && game.partner.customLogo ? game && game.partner.customLogo : './assets/images/logo_b.png')" width="140" alt="">

        </div>
        <!-- End of Logo container -->
        <swiper #usefulSwiper [config]="swiperConfig" class="mt-5" *ngIf="game">
          <div class="swiper-wrapper align-items-center">
            <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/7a.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>Welcome</h5>
              <p i18n>Flinkit is a new real world adventure game packed with codes to be cracked, clues to be uncovered, and puzzles to be solved in and outside. Fast, fun and easy to get going.</p>
            </div>
            <!-- End of Swiper -->
            <!-- Swiper -->
            <div class="swiper-slide" *ngIf="game.tourId.isBattleCircle">
              <img src="./assets/images/walkthrough/2.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>How to win?</h5>
              <p i18n>Pick up all points and solve riddles on the map, uncover the clues whilst staying in the battle circle.' </p>
            </div>
            <!-- End of Swiper -->
              <!-- Swiper -->
              <div class="swiper-slide" *ngIf="!game.tourId.isBattleCircle">
                <img src="./assets/images/walkthrough/2.png" alt="">
                <h5 class="fi-text-bold mt-3" i18n>How to win?</h5>
                <p i18n>Pick up all points and solve riddles on the map, uncover the clues.' </p>
              </div>
              <!-- End of Swiper -->
            <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/8.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>Questions & markers</h5>
              <p i18n>You can unlock questions by approaching them. The points are triggered when you stand in a 10 meters radius.</p>
            </div>
            <!-- End of Swiper -->
             <!-- Swiper -->
             <div class="swiper-slide" *ngIf="!game.partner">
              <img src="./assets/images/pois/backpack.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>Backpack</h5>
              <p i18n>Answering questions earns you points while collecting story points earns you valuable items which can be found in your backpack. </p>
            </div>
            <!-- End of Swiper -->
            <!-- Swiper -->
            <div class="swiper-slide" *ngIf="game.tourId.isBattleCircle">
              <img src="./assets/images/walkthrough/9.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>Battle circle</h5>
              <p i18n>You need to remain inside the battle circle during the game. Every position refresh outside the circle costs points.</p>
            </div>
            <!-- End of Swiper -->
                <!-- Swiper -->
                <div class="swiper-slide">
                  <img src="./assets/images/walkthrough/10.png" alt="">
                  <h5 class="fi-text-bold mt-3" i18n>End of the game</h5>
                  <p i18n>The game ends when you solve all the riddles or the time is up.</p>
                  <button *ngIf="mode === 'page'" class="flinkint-btn primary btn-block mt-3" [style.background-color]="bgColor" [style.border-color]="bgColor" (click)="jumpToGame()" i18n>I'm ready to start</button>
                  <button *ngIf="mode === 'embed'" class="flinkint-btn primary btn-block mt-3" (click)="jumpToGame(true)" i18n>Close</button>

                </div>
                <!-- End of Swiper -->
          </div>
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</div>