import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumPurchaseComponent } from './museum-purchase.component';
import { HttpClientModule } from '@angular/common/http';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgbDropdownModule,
    NgxUsefulSwiperModule
  ],
  declarations: [MuseumPurchaseComponent],
  exports: [MuseumPurchaseComponent]
})
export class MuseumPurchaseModule { }
