
  <div class="d-md-flex align-items-center justify-content-center position-relative pt-7-rex pb-5-rex" style="background:url(./assets/images/welcome.png); background-size: cover;">
      <a class="fl-logo-absolute" href="https://flinkit.io"><img src="./assets/images/logo_white.png" alt=""></a>

    <div class="fl-desktop-container p-3 position-relative d-block">
      <div class="inner shadow p-4 position-relative">
        <a href="https://www.producthunt.com/posts/flinkit-friends?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-flinkit-friends" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=291287&theme=light&period=daily" alt="Flinkit Friends - Story-driven outdoor adventure games for teams, and friends | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
        <h2 class="fi-text-bold mt-3">Location-based <span class="fi-text-lightblue">gaming platform <br> </span> for <span id="rotate" class="fi-text-lightblue"><span>enterprises.</span> <span>companies.</span> <span>team building.</span> <span>onboarding.</span></span></h2>
        <br class="d-block d-md-none">
        <a class="flinkint-btn lightblue mr-md-2 pr-5 pl-5 mt-3 " href="https://calendly.com/flinkitwebinar/30min?month=2021-03">Contact for demo</a>
        <button class="flinkint-btn outline lightblue  pr-5 pl-5 mt-3" data-toggle="modal" data-target="#videoModal">Play trailer</button>
        <div class="fi-text-bold mt-3"><i class="las la-map-marker mr-1"></i> Available in any cities. Anytime.</div>
   
        <img src="./assets/images/watch.png" data-toggle="modal" data-target="#videoModal" class="watch-icon fl-hoverer" width="40" alt="">
        <img src="./assets/images/telo.png" class="phone-icon d-none d-md-flex" alt="">
      </div>
      <div class="outer"></div>
      <div class="content">
        <h5 class="fi-text-bold mb-1">Watch how to play Flinkit!</h5>
        <div class="d-flex fi-desktop-footer justify-content-center w-100 mb-3">
          <div class="mr-2"><a href="mailto:info@flinkit.io">info@flinkit.io</a></div>|
          <div class="ml-2"><a href="https://calendly.com/flinkitwebinar/30min?month=2021-03" target="_blank">Book a call with us</a></div>
        </div>
        <div class="fi-desktop-footer">
          <a href="https://calendly.com/flinkitwebinar/30min?month=2021-03">Bringing the whole company? <br>    Or need special terms? Get in touch now!</a>
        </div>
      
      </div>
    </div>
  
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 pl-0 pr-0">
        <div class="pattern-bg plain2">
          <img src="./assets/images/ver2/footer.png" class="patter-ext green" alt="">
          <div class="row">
            <div class="col-12 text-center">
              <img src="./assets/images/ver2/slogo4.png" width="120" alt="">
            </div>
            <div class="col-12 text-center mt-3">
              <a href="https://www.youtube.com/channel/UCV0vkUmvW8G1U3xmbrHHgLw" class="mr-3"><img src="./assets/images/ver2/slogo.png" width="40" alt=""></a>
              <a href="https://www.facebook.com/flinkitgame" class="mr-3"><img src="./assets/images/ver2/slogo1.png" width="40" alt=""></a>
              <a href="https://www.linkedin.com/company/flinkit-global"><img src="./assets/images/ver2/slogo2.png" width="40" alt=""></a>
            </div>
            <div class="col-6 offset-3 text-center mt-4">
              <small class="text-light-color">© Flinkit! 2020 All rights reserved.</small><br>
              <small class="text-light-color">When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information
                to help provide you with a better, faster and safer experience and for marketing purposes.</small>
            </div>

          </div>
          
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal" id="videoModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered " style="height: initial !important;">
        <div class="modal-content">
            <div class="modal-body">
              <iframe height="400" style="width:100%;" src="https://www.youtube.com/embed/L9otgka3xGE" title="Flinkit! Friends!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
  </div>