import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseErrorComponent } from './purchase-error.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PurchaseErrorComponent]
})
export class PurchaseErrorModule { }
