import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MobileAppService } from '../services/mobile-app.service';

@Component({
  selector: 'app-purchase-success',
  templateUrl: './purchase-success.component.html',
  styleUrls: ['./purchase-success.component.scss'],
})
export class PurchaseSuccessComponent implements OnInit {
  pinCode;
  pageSettings = {
    joinMode: 'pin'
  }
  constructor(private router: Router, private route: ActivatedRoute, public mobileAppService: MobileAppService) {
    if (this.route.snapshot.paramMap.get('g') && this.route.snapshot.paramMap.get('p')) {
      this.pinCode = this.route.snapshot.paramMap.get('p');
    } else {
      this.navigateBack();
    }
  }

  navigateBack() {
    this.router.navigate(['/']);
  }


  sendCodesToEmail() {
    Swal.fire({
      title: 'Enter your email address',
      text: "Enter your email address and we'll send you the game PIN.",
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#4E3B74',
    }).then((result) => {
      if (result.isConfirmed) {
        // Send the email
        Swal.fire({
          title: 'Success',
          text: 'Check you mailbox',
          icon: 'success',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4E3B74',
        });
      }
    });
  }

  share(){
    if (navigator.share) {
      navigator.share({
        title: 'Flinkit! Ourdoor! | Join game',
        url: this.mobileAppService.getURL()+'en/pin;p='+this.pinCode.toString()
      }).then(() => {
        
      })
      .catch(console.error);
    } else {
      console.log('Share is not supported')
    }
  }
  joinGame() {
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/7a.png',
      title: 'Join game',
      text: "Make sure you saved your PIN code and shared with your fellow players.",
      showCancelButton: true,
      confirmButtonText: 'Jump to join',
      confirmButtonColor: '#4E3B74',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/pin', {p: this.pinCode}]);
      }
    });

    
  }

  ngOnInit() {}
}
