import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { HttpClientModule } from '@angular/common/http';
import { WalkthroughModule } from '../components/walkthrough/walkthrough.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgxUsefulSwiperModule,
    HttpClientModule,
    WalkthroughModule,
    NgbModule
  ],
  declarations: [LandingComponent],
  exports: [LandingComponent]
})
export class LandingModule { }
