

  import { Injectable } from '@angular/core';
import Swal from "sweetalert2";
import { GameService } from './game.service';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
 
  constructor() { }


  writeStorage(name, obj){
    if (typeof(Storage) !== "undefined") {
        localStorage.setItem(name, obj);
    } else{
        console.log('No web storage supported');
    }
  }

  getStorage(name){
    if (typeof(Storage) !== "undefined") {
        return JSON.parse(localStorage.getItem(name));
    } else{
        console.log('No web storage supported');
    }
  }

  removeStorage(name){
    if (typeof(Storage) !== "undefined") {
        return localStorage.removeItem(name);
    } else{
        console.log('No web storage supported');
    }
  }

  

  


}
