import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalkthroughComponent } from './walkthrough.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

@NgModule({
  imports: [
    CommonModule,
    NgxUsefulSwiperModule,
  ],
  declarations: [WalkthroughComponent],
  exports: [WalkthroughComponent]
})
export class WalkthroughModule { }
