import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutComponent } from './checkout/checkout.component';
import { EndGameModule } from './endGame/endGame.module';
import { EndGameComponent } from './endGame/endGame.component';

import { GameComponent } from './game/game.component';
import { LandingComponent } from './landing/landing.component';
import { PinComponent } from './pin/pin.component';
import { PurchaseSuccessComponent } from './purchase-success/purchase-success.component';
import { WalkthroughModalComponent } from './components/walkthroughModal/walkthroughModal.component';
import { PurchaseErrorComponent } from './purchase-error/purchase-error.component';
import { PaymentComponent } from './payment/payment.component';
import { MuseumsComponent } from './museums/museums.component';
import { MuseumLandingComponent } from './museum-landing/museum-landing.component';
import { MuseumOnboardingComponent } from './museum-onboarding/museum-onboarding.component';
import { MuseumWalkthroughComponent } from './museum-walkthrough/museum-walkthrough.component';
import { MuseumPurchaseComponent } from './museum-purchase/museum-purchase.component';
import { MuseumLeaderboardComponent } from './museum-leaderboard/museum-leaderboard.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { StartScreenComponent } from './start-screen/start-screen.component';
import { EndScreenComponent } from './end-screen/end-screen.component';
import { MuseumGamepassComponent } from './museum-gamepass/museum-gamepass.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { TouristComponent } from './tourist/tourist.component';
import { TouristSuccessComponent } from './touristSuccess/touristSuccess.component';
import { HelpComponent } from './help/help.component';
import { MuseumDirectComponent } from './museum-direct/museum-direct.component';
import { HelloComponent } from './hello/hello.component';


const routes: Routes = [
   {
    path:'',
    component: LandingComponent
  }, 
  {
    path:'landing',
    component: LandingComponent
  },
  {
    path:'showcase',
    component: ShowcaseComponent
  },
  {
    path:'pin',
    component: PinComponent,
  }, {
    path:'tourist',
    component: TouristComponent
  }, {
    path:'touristSuccess',
    component: TouristSuccessComponent
  },{
    path:'game',
    component: GameComponent,
  }, {
    path:'checkout',
    component: CheckoutComponent,
  }, {
    path:'success',
    component: PurchaseSuccessComponent,
  }, {
    path:'error',
    component: PurchaseErrorComponent,
  }, {
    path:'endGame',
    component: EndGameComponent,
  }, {
    path:'payment',
    component: PaymentComponent,
  }, {
    path:'walkthrough',
    component: WalkthroughModalComponent,
  }, {
    path:'onboarding',
    component: OnboardingComponent,
  },{
    path:'museums',
    component: MuseumsComponent,
  },{
    path:'museum-landing',
    component: MuseumLandingComponent,
  }, {
    path:'museum-onboarding',
    component: MuseumOnboardingComponent,
  },{
    path:'museum-walkthrough',
    component: MuseumWalkthroughComponent,
  }, {
    path:'museum-purchase',
    component: MuseumPurchaseComponent,
  }, {
    path:'museum-leaderboard',
    component: MuseumLeaderboardComponent,
  },  {
    path:'museum-gamepass',
    component: MuseumGamepassComponent,
  },{
    path:'museum-direct',
    component: MuseumDirectComponent,
  },  {
    path:'start-screen',
    component: StartScreenComponent,
  }, {
    path:'end-screen',
    component: EndScreenComponent,
  },  {
    path:'help',
    component: HelpComponent,
  }, {
    path:'hello',
    component: HelloComponent,
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
