import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumLandingComponent } from './museum-landing.component';
import { HttpClientModule } from '@angular/common/http';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ZXingScannerModule,
    NgbDropdownModule

    
  ],
  declarations: [MuseumLandingComponent]
})
export class MuseumLandingModule { }


