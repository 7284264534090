import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../services/utils.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { LocaleService } from '../services/locale.service';
import { LocationService } from '../services/location.service';
import Noty from 'noty';
import { MobileAppService } from '../services/mobile-app.service';

@Component({
  selector: 'app-museum-landing',
  templateUrl: './museum-landing.component.html',
  styleUrls: ['./museum-landing.component.scss'],
})
export class MuseumLandingComponent implements OnInit {
  bgColor;
  partner;
  pageConfig = {
    isAirbnb: false,
    showQrReader: false,
    loaders: {
      validateGame: false,
    },
  };
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private utilsService: UtilsService,
    public localeService: LocaleService,
    private locationService: LocationService,
    private  ngZone: NgZone,
    private mobileAppService: MobileAppService
  ) {
    this.detectLocale();
  }

 
  
  scanQR(){
    this.utilsService.dropSwal('Scan QR','Please scan your QR code, with the camera of your smartphone.','info');
  }

  dropNoty(type, text, timeout?) {
    new Noty({
      type: type,
      text: text,
      killer: true,
      timeout
    }).show();
  }

  refreshPosition(pc,uid,tid){
  
    this.dropNoty('info', this.localeService.getTrans('Checking location data') + '<i class="las la-circle-notch ml-1 la-spin"></i>');
    this.locationService.getPosition().then((x: any)=>{
      this.ngZone.run(() => {
        if(x.coords){
          this.dropNoty('success', this.localeService.getTrans('Location found, you can join to the game.'), 5000);
          this.router.navigate(['/museum-onboarding'], {
            queryParams: {
              pc,
              uid,
              tid
            },
          }); 
        }    
        });
    }, (err)=>{
      Swal.fire({
        title: this.localeService.getTrans('Cannot access location'),
        text: this.localeService.getTrans('You need to grant access to the browser to start the game.'),
        icon: 'error',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: this.localeService.getTrans(`Got it`),
        confirmButtonColor: this.bgColor ? this.bgColor : '#4E3B74',
        cancelButtonText: this.localeService.getTrans('Back'),
        heightAuto: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.refreshPosition(pc,uid,tid);
        } 
      })
    })
  }
  
  subscribeForGame(tid, pc, uid, isTest, groupId, showcase){
    this.pageConfig.loaders.validateGame = true;
    return this.http
    .post(
      environment.baseUrl + 'partnerPayments/createPartnerPayment',
      {
        tourId: tid,
        paymentIdentifier: pc,
        partner: uid,
        isTest,
        groupId,
        showcase
      }
    )
    .subscribe((data: any) => {

      if (data.success) {
        this.bgColor = data.data ? (data.data.partner ? data.data.partner.color : '') : '';

        if(data.type === 'redirect'){
          if(data.data && !data.data.isQR){
            this.refreshPosition(pc,uid,tid);
          } else{
            setTimeout(()=>{  
              this.router.navigate(['/museum-onboarding'], {
                queryParams: {
                  pc,
                  uid,
                  tid,
                  showcase
                },
              }); 
              this.pageConfig.loaders.validateGame = false;
            },5000);
            
          }
         
        } else{
             this.router.navigate(['/game', {
              p: data.data.players[0].name,
              g: data.data._id
            }]); 
            this.pageConfig.loaders.validateGame = false;
          
        }      
      } else {
        Swal.fire({
          icon: 'info',
          text: data.msg,
          showDenyButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: this.localeService.getTrans(`Got it`),
          confirmButtonColor: '#4E3B74',
        });
        this.pageConfig.loaders.validateGame = false;
      }
     
    });
  }

  navigateToPurchase(){
    this.router.navigate(['/museum-purchase'],{
      queryParams: {
        uid: this.partner._id
      }
    });
  }

  switchLanguage(locale){
    let localeString = '/'+locale+'/';
    let redirectString = this.mobileAppService.getURL()+locale+'/' + window.location.href.substr(window.location.href.indexOf('/museum-landing'),window.location.href.length);
    window.location.href = redirectString;
  }

  jumpToLeaderboard(){
    this.router.navigate(['/museum-leaderboard', {
      p: this.partner._id
    }]);
  }

  getPartnerDetails(_id){
    return this.http
    .post(
      environment.baseUrl + 'partners/getPartner',
      {
        _id
      }
    )
    .subscribe((data: any) => {

      if(data.success){
        this.partner = data.data;
        console.log(this.partner)
      } else{
       // window.location.href = this.mobileAppService.getURL()+'en/museums';
      }
      
    });
  }

  detectLocale(){
    if(window.location.href.indexOf('/hu/') !== -1){
      this.localeService.locale = 'hu';
    } else if(window.location.href.indexOf('/de/') !== -1){
      this.localeService.locale = 'de';
    } else if(window.location.href.indexOf('/fr/') !== -1){
      this.localeService.locale = 'fr';
    } else{
      this.localeService.locale = 'en';
    }
  }
  
  ngOnInit() {
    if (this.locationService.isValidBrowser()) {
      this.route.queryParams.subscribe((params) => {
        console.log(params);
        if (!params.uid) {
          window.location.href = this.mobileAppService.getURL()+'en/museums';
        } 
        this.getPartnerDetails(params.uid);

        
        if (params.pc && params.uid && params.tid) {
          if(params.isAirbnb){
            this.pageConfig.isAirbnb = true;
          }
          this.pageConfig.loaders.validateGame = true;
          // check for location and browser
          this.subscribeForGame(params.tid, params.pc, params.uid, params.type, params.groupId, params.showcase);
        } else{
          this.pageConfig.loaders.validateGame = false;
        }
      });
    }
    this.detectLocale();
  }
}
