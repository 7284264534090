import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../services/utils.service';
import Swal from 'sweetalert2';
import { EmojiServiceService } from '../services/emoji-service.service';
import { LocaleService } from '../services/locale.service';
import * as _ from 'lodash';
import { SwiperComponent } from 'ngx-useful-swiper';
import { MobileAppService } from '../services/mobile-app.service';
import { LogService } from '../services/log.service';
declare var Stripe;
@Component({
  selector: 'app-museum-purchase',
  templateUrl: './museum-purchase.component.html',
  styleUrls: ['./museum-purchase.component.scss'],
})

export class MuseumPurchaseComponent implements OnInit {
  @ViewChild('usefulSwiper',{static: true}) usefulSwiper: SwiperComponent;
  partner;
  stripe;
  partnerGames = [];
  loaders = true;
  selectedLanguage;
  selectedTour;
  checkoutObj = {
    tour: null
  };
  iconLocale = 'uk';
  swiperConfig = {
    loop: false,
    slidesPerView: 'auto',
    centeredSlides: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private utilsService: UtilsService,
    public localeService: LocaleService,
    public emojiService: EmojiServiceService,
    private mobileAppService: MobileAppService,
    private logService: LogService
  ) {}

  switchLanguage(locale){
    let localeString = '/'+locale+'/';
    let redirectString = this.mobileAppService.getURL()+locale+'/' + window.location.href.substr(window.location.href.indexOf('museum-purchase'),window.location.href.length);
    window.location.href = redirectString;
  }

  getPartnerGames(){
    return this.http
    .post(environment.baseUrl + 'tours/getPartnerTours', {
      partner: this.partner._id,
    })
    .subscribe((data: any) => {
      console.log(data);
      if (data.success) {
        this.loaders = false;
        this.partnerGames =  _.map(_.filter(data.data, (p)=>{return p.price !== 0}), (tour)=>{
          return _.merge(tour, {isSelected: false});
        });
        this.selectTour(this.partnerGames[0]);
      }
    });
  }

  logVisit(partner, type){
    this.logService.log(partner._id, partner.name, type, 0);

   /*  return this.http.post(environment.baseUrl + 'logs/createLog', {
     partner: partner._id,
     type
    })
    .subscribe((data: any) => {
      console.log(data)
    }); */
  }
  getPartnerDetails(_id, qrLog) {
    return this.http
      .post(environment.baseUrl + 'partners/getPartner', {
        _id,
      })
      .subscribe((data: any) => {
        console.log(data);
        if (data.success) {
          this.partner = data.data;
         
          //&& this.partner._id === '642272236c9780c359cf109b'
          if(this.partner.isPayingForQrCode){
            this.getPartnerGames();
            this.logVisit(this.partner, 0)
          } else{
            window.location.href = this.mobileAppService.getURL()+'en/hello';
          }
         

        } else {
          window.location.href = this.mobileAppService.getURL()+'museums';
        }
      });
  }

  selectTour(tour){
    _.each(this.partnerGames, (p)=>{
      p.isSelected = false;
    });
    if(this.selectedTour === tour){
      tour.isSelected = false;
      this.selectedTour = null;
    } else{
      tour.isSelected = true;
      this.selectedTour = tour;
    }
    
  }


  checkout(){

    this.loaders = true;
    this.logVisit(this.partner, 2);
    window.location.href = this.mobileAppService.getURL() + this.localeService.locale  +'/museum-direct?uid='+this.selectedTour.partner+'&locale='+this.localeService.locale+'&tid='+this.selectedTour._id+'&groupId=' + this.generatePassCode();
   
      
        
  }

  generatePassCode() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  detectLocale(){
    if(window.location.href.indexOf('/hu/') !== -1){
      this.localeService.locale = 'hu';
      this.iconLocale = 'hu';
    } else if(window.location.href.indexOf('/de/') !== -1){
      this.localeService.locale = 'de';
      this.iconLocale = 'de';
    } else if(window.location.href.indexOf('/fr/') !== -1){
      this.localeService.locale = 'fr';
      this.iconLocale = 'fr';
    } else{
      this.localeService.locale = 'en';
      this.iconLocale = 'uk';
    }
  }

  ngOnInit() {
    this.stripe = Stripe(environment.stripe);
    if (this.utilsService.checkDeviceCompatibility()) {
      this.route.queryParams.subscribe((params: any) => {
      
        if(params.type && params.type === 'airbnb'){
          //this.router.navigate(['/tourist'])
          window.location.href = '/en/tourist';
        }
        if (!params.uid) {
         window.location.href = 'https://outdoor.flinkit.io/museums';
        } else {
          this.getPartnerDetails(params.uid, params.log);
        }
      });
    }
    this.detectLocale();
  }
}
