import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumDirectComponent } from './museum-direct.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MuseumDirectComponent],
  exports: [MuseumDirectComponent]
})
export class MuseumDirectModule { }
