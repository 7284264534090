import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumsComponent } from './museums.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MuseumsComponent],
  exports: [MuseumsComponent]
})
export class MuseumsModule { }
