import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileAppService } from '../services/mobile-app.service';
import Swal from "sweetalert2";
import { LocaleService } from '../services/locale.service';

declare var detect;
@Component({
  selector: 'app-museum-direct',
  templateUrl: './museum-direct.component.html',
  styleUrls: ['./museum-direct.component.scss']
})
export class MuseumDirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private mobileAppService: MobileAppService, private localeService: LocaleService) { }

  generatePassCode() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  //http://localhost:4200/museum-direct?uid=618378ed841e04b35e9294a5&locale=en&tid=629f57cf1b411f24ccabc351&groupId=fdfeu32uu323

  isValidBrowser(){

    if(detect){
      let ua = detect.parse(navigator.userAgent);
      
      let isSafari = ua.browser.family.indexOf('Safari') === -1 ? false : true;
      let isChrome = ua.browser.family.indexOf('Chrome') === -1 ? false : true;
      let isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
      if(isSamsungBrowser){
        this.dropErrorBrowser();
        return false;
      } else{
        if(isChrome || isSafari){
          return true;
        } else{
          this.dropErrorBrowser();
          return false;
        }
      }
    } else{
     return true;
    }  
  }

  dropErrorBrowser(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/6a.png',
      title: this.localeService.getTrans('Not supported browser'),
      text: this.localeService.getTrans("The game only supports Chrome or Safari mobile browsers. "),
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Got it`),
      confirmButtonColor: '#4E3B74'
    }).then((result) => {
       this.isValidBrowser();
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params.uid && params.tid && params.groupId ) {
        if(this.isValidBrowser()){
          let url = this.mobileAppService.getURL() + params.locale + '/museum-landing?uid=' + params.uid + '&pc=' + this.generatePassCode() + '&tid=' + params.tid + '&groupId=' + params.groupId;
          console.log(url)
          window.location.href = url;
        }
      }
    
    });
  }

}
