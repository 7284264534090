import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from 'ngx-useful-swiper';
import { EmojiServiceService } from '../services/emoji-service.service';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from '../services/location.service';
import { LocaleService } from '../services/locale.service';
import { StorageService } from '../services/storage.service';
import Noty from 'noty';
declare var detect;
@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit {
  @ViewChild('usefulSwiper',{static: false}) usefulSwiper: SwiperComponent;
  @ViewChild('pinDigit1', {static: true}) inputEl1:ElementRef;
  @ViewChild('pinDigit2', {static: true}) inputEl2:ElementRef;
  @ViewChild('pinDigit3', {static: true}) inputEl3:ElementRef;
  error;
  activeGame;
  isLocation = false;
  polledGame;
  pollInterval;
  pinCode = ['0','0','0'];
  bgColor;
  isLeader = false;
  swiperConfig = {
    spaceBetween: 10,
    loop: false,
    initialSlide: 2,
    slidesPerView: 'auto',
    loopedSlides: 2,
    centeredSlides: true
  };
  coords;
  pageConfig = {
    step: 0,
    loginStep: 0,
    isLocalStorageAvailable: false,
    loaders: {
      getPIN:false,
      joinGame: false,
      startGame: false

    }
  };
  playerObj = {
    teamName: null,
    avatarIndex: 0,
    passedQuests: []
  };
  browser;

  constructor(public emojiService: EmojiServiceService, private http: HttpClient, private router: Router, private route: ActivatedRoute, private locationService: LocationService,  private ngZone: NgZone, public localeService: LocaleService, private storageService: StorageService) {
    if (this.route.snapshot.paramMap.get('p')) {
      this.pinCode[0] = this.route.snapshot.paramMap.get('p')[0].toString();
      this.pinCode[1] = this.route.snapshot.paramMap.get('p')[1].toString();
      this.pinCode[2] = this.route.snapshot.paramMap.get('p')[2].toString();
      //this.validatePIN();
    } 
    this.locationService.isValidBrowser();
    if(detect){
      let ua = detect.parse(navigator.userAgent);
        this.browser = ua.browser.family;
    }
  }

  setAvatar(){
    this.playerObj.avatarIndex = this.usefulSwiper.swiper.activeIndex;
  }

  setAvatarIndex(){
    this.playerObj.avatarIndex = this.usefulSwiper.swiper.activeIndex;
  }

  

  dropSwal(title, content, type){
    Swal.fire({
      title: title,
      text: content,
      icon: type,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#4E3B74',
      heightAuto: false
    })
  }

  removePlayer(player){
    _.remove(this.polledGame.players, (x)=>{
      return x._id === player._id;
    })
    return this.http.post(environment.baseUrl + 'games/removePlayer', {gameId: this.activeGame._id, playerObj: this.playerObj, player} ).subscribe((data: any) => {
      console.log(data);
    });
  }

  pollGame(){
    return this.http.post(environment.baseUrl + 'games/getGameStatus', {gameId: this.activeGame._id, playerObj: this.playerObj} ).subscribe((data: any) => {
      console.log(data);
      this.polledGame = data.data;
      if(this.polledGame.isStarted){
        clearInterval(this.pollInterval);
        this.router.navigate(['/walkthrough', {g: this.polledGame._id, p: this.playerObj.teamName}])
      } else{
        let me = _.find(data.data.players, (p)=>{
          return p.name === this.playerObj.teamName
        });
        if(me){
          this.isLeader = _.findIndex(data.data.players, (p)=>{
            return p.name === this.playerObj.teamName
          }) === 0 ? true : false;
          this.isLeader = true;
        } else{
          clearInterval(this.pollInterval);
          this.router.navigate(['/'])
        }
      }
    }); 
  }

  startGame(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/1.png',
      title: this.localeService.getTrans('Do you really want to launch the game?'),
      text: this.localeService.getTrans('The game will start for every other players by clicking "Start game"'),
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Start game`),
      confirmButtonColor: this.bgColor ? this.bgColor :'#4E3B74',
      cancelButtonText: this.localeService.getTrans('Back')
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(this.coords)
        this.pageConfig.loaders.startGame = true;
        return this.http.post(environment.baseUrl + 'games/startGame', {gameId: this.activeGame._id, playerObj: this.playerObj, coords: {
          lat: this.coords.latitude,
          lng: this.coords.longitude
        }} ).subscribe((data: any) => {
          console.log(data);
          this.pageConfig.loaders.startGame = false;
        }); 
      } 
    })
   
  }

  joinGame(){
    console.log('join game')
    this.pageConfig.loaders.joinGame = true;
    return this.http.post(environment.baseUrl + 'games/joinGame', {gameId: this.activeGame._id, playerObj: this.playerObj} ).subscribe((data: any) => {
      console.log(data)
      if(data && data.data.success && data.data.status){
        this.storageService.writeStorage('fl-game-details',JSON.stringify({activeGame: this.activeGame, playerObj: this.playerObj}));
        if(data.data.isStarted){
          this.router.navigate(['/walkthrough', {g: data.data.status._id, p: this.playerObj.teamName}])
        } else {
          this.pageConfig.step = 2;
          this.pollInterval = setInterval(()=>{
            this.pollGame();
          }, 3000);
        }
       
      } else{
        Swal.fire({
          title: 'Oops',
          text: data.data.msg,
          icon: 'error',
          confirmButtonText: 'Got it',
          showCancelButton: true,
          cancelButtonText: 'Erase players',
          confirmButtonColor: this.bgColor ? this.bgColor :'#4E3B74',
          heightAuto: false,
        }).then((result) => {
          console.log(result)
          if (result.isDismissed) {
            if(!this.activeGame.isStarted){
              return this.http.post(environment.baseUrl + 'games/erasePlayers', {gameId: this.activeGame._id, playerObj: this.playerObj} ).subscribe((data: any) => {
                console.log('Players erased');
              });
            } else{
              Swal.fire({
                title: 'Oops',
                text: 'The game has been already started. You cannot erase players now.',
                icon: 'error',
                confirmButtonText: 'Got it',
                confirmButtonColor: this.bgColor ? this.bgColor :'#4E3B74'
              })
            }
        
          } 
        })
      }
      this.pageConfig.loaders.joinGame = false;
    });
  }

  validatePIN(withRedirect?){
    this.pageConfig.loaders.getPIN = true;
    if(!this.pinCode[0]){
      this.pinCode[0] = '0';
    }
    if(!this.pinCode[1]){
      this.pinCode[1] = '0';
    }
    if(!this.pinCode[1]){
      this.pinCode[1] = '0';
    }
    return this.http.post(environment.baseUrl + 'games/validatePIN', {pin: parseInt(this.pinCode[0] + this.pinCode[1] + this.pinCode[2])} ).subscribe((data: any) => {
      if(data && data.success && data.data){
        this.localeService.locale = data.data.tourId.locale ? data.data.tourId.locale : 'en';
        

        let localeString = '/'+this.localeService.locale+'/';
        let redirectNeeded = false;

        if(window.location.pathname.indexOf(localeString) === -1){
          redirectNeeded = true;
        }

         if((!withRedirect && !redirectNeeded) || (withRedirect && !redirectNeeded)){
          if(!data.data.isSample){
            this.activeGame = data.data;
            this.pageConfig.step = 1;
          
          } else{
            if(moment().unix()<data.data.sampleValidity){
              this.activeGame = data.data;
              this.pageConfig.step = 1;
            } else{
              this.dropSwal(this.localeService.getTrans('PIN error'), this.localeService.getTrans('This PIN has expired'), 'error');
            }
           
          }
          if(this.activeGame.partner && this.activeGame.partner.color){
            this.bgColor = this.activeGame.partner ? ((this.activeGame.tourId.customBranding && this.activeGame.tourId.customBranding.color) ? this.activeGame.tourId.customBranding.color : this.activeGame.partner.color ) : null;
           }
        }
       
     

          if(withRedirect && redirectNeeded){
          let localeString = '/'+this.localeService.locale+'/';
          if(window.location.pathname.indexOf(localeString) === -1){
            window.location.pathname = '/'+this.localeService.locale+'/pin;p='+parseInt(this.pinCode[0] + this.pinCode[1] + this.pinCode[2]);
          }
        } 
       
       
       
    

      } else{
       
       this.dropSwal(this.localeService.getTrans('Wrong PIN'), this.localeService.getTrans('There is no game with this PIN'), 'error');
      }
      this.pageConfig.loaders.getPIN = false;
    });
    
  }

  setFocus(id){
    document.getElementById('pinDigit'+id).focus();
  }

  dropNoty(type, text, timeout?) {
    new Noty({
      type: type,
      text: text,
      killer: true,
      timeout
    }).show();
  }

  refreshPosition(){
  
    this.dropNoty('info', 'Checking location data' + '<i class="las la-circle-notch ml-1 la-spin"></i>');
    this.locationService.getPosition().then((x: any)=>{
      this.ngZone.run(() => {
        if(x.coords){
          this.dropNoty('success', this.localeService.getTrans('Location found, you can join to the game.'), 5000);
          this.coords = x.coords;
          this.isLocation = true;
        }    
        });
    }, (err)=>{
      this.isLocation = false;
      Swal.fire({
        title: this.localeService.getTrans('Cannot access location'),
        text: this.localeService.getTrans('You need to grant access to the browser to start the game.'),
        icon: 'error',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: `Start game`,
        confirmButtonColor: this.bgColor ? this.bgColor : '#4E3B74',
        cancelButtonText: 'Back',
        heightAuto: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.refreshPosition();
        } 
      })
    
    })
  }

  joinToPreviousGame(){
      let previousGame = this.storageService.getStorage('fl-game-details');
      this.activeGame = previousGame.activeGame;
      this.playerObj = previousGame.playerObj;
      this.joinGame();
  }


  ngOnInit() {
    this.refreshPosition();
    if(this.storageService.getStorage('fl-game-details')){
      this.pageConfig.isLocalStorageAvailable = true;
    }
  }

}
