<!-- Step 0 -->
<div class="flinkit-full-bg"  *ngIf="pageConfig.step === 0" [style.background-color]="activeGame ? (activeGame.partner ? activeGame.partner.color : null) : null" >
  <div class="row h-100 mr-0 ml-0">
  
    <div class="col-12 d-flex align-items-center justify-content-center h-100">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center text-white mb-2" *ngIf="activeGame && activeGame.tourId">
          <img [src]="activeGame.tourId.isCustomBranding ? activeGame.tourId.customBranding.cover : (activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/logo_bp.png')" width="140" alt="">
          
        </div>
        <!-- End of Logo container -->
        <div class="text-center flex-fill">
          <h4 class="text-white font-weight-bold" i18n >Game PIN</h4>
          <div class="flinkit-pin-code-container d-inline-flex mt-3" >
            <div class="pin-input-container mr-2" >
              <input type="text" pattern="\d*" maxlength="1" #pinDigit1 id="pinDigit1" [(ngModel)]="pinCode[0]" (keyup)="setFocus(2)"  (focus)="pinCode[0] = null"  placeholder="0">
            </div>
            <div class="pin-input-container mr-2" mask="0" >
              <input type="text" pattern="\d*" maxlength="1" #pinDigit2 id="pinDigit2" [(ngModel)]="pinCode[1]" (keyup)="setFocus(3)" (focus)="pinCode[1] = null"  placeholder="0">
            </div>
            <div class="pin-input-container" mask="0">
              <input type="text" pattern="\d*" maxlength="1" #pinDigit3 id="pinDigit3" [(ngModel)]="pinCode[2]" (focus)="pinCode[2] = null"  placeholder="0">
            </div>
          </div>
          <div class="mt-3">
            <button class="flinkint-btn white btn-block" [disabled]="pageConfig.loaders.getPIN" *ngIf="pageConfig.isLocalStorageAvailable" (click)="joinToPreviousGame()" >
              <span *ngIf="pageConfig.loaders.getPIN else loginBlock1"><i class="las la-circle-notch la-2x la-spin"></i></span>
              <ng-template #loginBlock1><span i18n>Rejoin to game</span></ng-template>
            </button>
            <button class="flinkint-btn white btn-block mt-2" [style.color]="activeGame ? (activeGame.partner ? activeGame.partner.color : null) : null" [disabled]="pageConfig.loaders.getPIN || !isLocation" (click)="validatePIN(true)" >
              <span *ngIf="pageConfig.loaders.getPIN else loginBlock"><i class="las la-circle-notch la-2x la-spin"></i></span>
              <ng-template #loginBlock><span i18n>Join game</span></ng-template>
            </button>
            <div>
              <small class="text-danger pet-text-bold fw-bold mt-3" style="font-weight: bold;" *ngIf="!isLocation">{{localeService.getTrans('Cannot access location')}}</small>
            </div>
          </div>
          <div class="mt-3">
            <div class="text-white flinkit-text-small" i18n>Ask your fellow players for the game PIN received after purchasing the game.</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- End of Step 0 -->
<!-- Step 1 -->
<div class="flinkit-section-bg" *ngIf="pageConfig.step === 1">
  <div class="flinkit-section-bg">
    <div class="flinkit-new-page-content-section">
      <div class="upper" [style.background-color]="bgColor">
        <div class="content">
          <!-- Logo container -->
          <div class="text-center text-white">
          <img [src]="activeGame.tourId.isCustomBranding ? activeGame.tourId.customBranding.cover : (activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/logo_bp.png')" width="140" alt="">

          </div>
          <!-- End of Logo container -->
          <div class="row mt-3 text-center">
            <div class="col-12" data-aos="fade-up" data-aos-delay="100">
              <h6 class="flinkit-event-text"><span i18n>Active game</span> (PIN: {{pinCode[0]}}{{pinCode[1]}}{{pinCode[2]}})</h6>
              <h4 class="fi-text-bold text-white">{{(activeGame && activeGame.tourId) ? activeGame.tourId.name : '-'}}</h4>
            </div>
          </div>
        </div>
        <!-- Divider-->
        <div class="flinkit-divider">
          <img src="./assets/images/divider.png" class="img-fluid" alt="">
        </div>
        <!-- End of Divider-->
      </div>

      <div class="bottom d-flex align-items-center justify-content-center">
        <div class="w-75">
          <!-- Step 1 -->
          <div class="form-group"  data-aos="fade-left" data-aos-delay="200" *ngIf="pageConfig.loginStep === 0">
            <label class="fi-text-bold" i18n>Player name</label>
            <input type="text" [(ngModel)]="playerObj.teamName" autofocus [maxlength]="20" placeholder="Player name" class="form-control fi-form-control">
            <small class="flinkit-text-small fi-text-bold text-danger" *ngIf="playerObj &&  playerObj.teamName && playerObj.teamName.length < 5 "><span i18n>At least</span> {{5-playerObj.teamName.length}} <span i18n>characters more</span></small>
            <div class="form-group has-error" *ngIf="error">
              <small class="help-block bg-danger-alt text-danger">{{error}}</small>
            </div>
            <div class="form-group">
              <small class="help-block bg-danger-alt"><i class="las la-info-circle "></i><span i18n>This will be your player name in the game</span></small>
            </div>
            <button class="flinkint-btn primary btn-block mt-3" [style.background-color]="bgColor" [style.border-color]="bgColor" [disabled]="!playerObj.teamName || playerObj.teamName.length < 5 " (click)="pageConfig.loginStep = 1"  data-aos="fade-left" i18n>Next</button>
            <button class="flinkint-btn primary outline btn-block mt-2" [style.border-color]="bgColor" [style.color]="bgColor" (click)="pageConfig.step = 0" i18n>Back</button>

          </div>
          <!-- End of Step 1 -->
          <!-- Step 1 -->
          <div class="form-group"  data-aos="fade-left"  *ngIf="pageConfig.loginStep === 1">
            <h4 class="fi-text-bold text-center"><span i18n>Hello</span>&nbsp;<span class="fi-text-primary"> {{playerObj.teamName}}!</span>&nbsp; <span i18n>Set avatar!</span></h4>
            <swiper [config]="swiperConfig"  #usefulSwiper class="mt-3">
              <div class="swiper-wrapper d-flex align-items-center">
                <div class="swiper-slide emojis" *ngFor="let emoji of emojiService.getEmojis(); let i = index" (click)="setAvatarIndex(i)"><img [src]="emoji.icon" alt=""></div>
              </div>
            </swiper>
            <div class="row mt-2">
              <div class="col-12 text-center">
                <div class="arrow-up ml-auto mr-auto"></div>
              </div>
            </div>
            <button class="flinkint-btn primary btn-block mt-3" [style.background-color]="bgColor" [style.border-color]="bgColor" [disabled]="pageConfig.loaders.joinGame" (click)="setAvatar(); joinGame()">
              <span *ngIf="pageConfig.loaders.joinGame else loaderBlock"><i class="las la-circle-notch la-2x la-spin"></i></span>
              <ng-template #loaderBlock> <span i18n>Join lobby</span></ng-template>
            </button>
            <div class="row mt-1 text-center">
              <div class="col-12">
                <button class="flinkint-btn primary outline btn-block mt-1" [style.border-color]="bgColor" [style.color]="bgColor" (click)="pageConfig.loginStep = 0" i18n>Back</button>
              </div>
            </div>
          </div>
          <!-- End of Step 1 -->

          <div class="row text-center" *ngIf="activeGame && activeGame.partner && activeGame.partner.isExclusiveBranded">
            <div class="col-12 text-center fi-text-bold">
              <img [src]="activeGame.partner.isWhiteLabel ? activeGame.partner.logo  :'./assets/images/pbe.png'" width="90" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- Step 1 -->
<!-- Step 2 (Lobby) -->
<div class="flinkit-full-bg overflow-auto" *ngIf="pageConfig.step === 2" [style.background-color]="bgColor">
  <div class="row mr-0 ml-0 pt-5 pb-5">
    <div class="col-12 d-flex align-items-center justify-content-center h-100">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center text-white">
          <img [src]="activeGame.tourId.isCustomBranding ? activeGame.tourId.customBranding.cover : (activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/logo_bp.png')" width="180" alt="">
        </div>
        <!-- End of Logo container -->
        <div class="text-center mt-5 flex-fill">
          <h3 class="text-white fi-text-bold" *ngIf="polledGame"  data-aos="fade-up">{{polledGame.players.length}} <span i18n>players joined.</span> <br> <span *ngIf="polledGame.noOfPlayers !== polledGame.players.length">{{polledGame.noOfPlayers-polledGame.players.length}} <span i18n>others can join.</span></span></h3>
          <h4 class="text-white fi-text-light" data-aos="fade-up" i18n>The game starts when the game creator clicks 'Start game'.</h4>
          <div class="w-100 mt-5 text-white" *ngIf="!isLeader else leaderBlock">
            <h6 class="fi-text-light"><i class="las la-circle-notch la-spin fs-1-3"></i><span i18n> Waiting for players</span></h6>
          </div>
          <ng-template #leaderBlock>
            <div class="w-100 mt-3 text-white">

              <div class="row mt-3">
                <div class="col-12">
                  <div class="fi-result-row p-3 shadow d-flex align-items-center mb-2" *ngFor="let player of polledGame.players; let i = index;">
                    <div class="mr-3">
                      <div class="fi-map-marker-enemy bg-2">
                        <img [src]="emojiService.getEmojiByIndex(player.avatarIndex).icon" width="30" alt="">
                      </div>
                    </div>
                    <div class="text-left">
                      <h4 class="mb-0 text-dark"> {{player.name}}</h4>
                    </div>
                    <div class="ml-auto" *ngIf="player.name !== playerObj.teamName && polledGame.players[0].name === playerObj.teamName">
                      <button (click)="removePlayer(player)" class="btn btn-light"><i class="las la-trash la-2x"></i></button>
                    </div>
                  
                  </div>
                </div>
              </div>
            

            
            </div>
            <div class="w-100 mt-3 text-white" *ngIf="polledGame.players[0].name === playerObj.teamName">
              <button class="flinkint-btn white btn-block mt-3 pt-3 pb-3" [style.border-color]="bgColor" [style.color]="bgColor" [disabled]="pageConfig.loaders.startGame || !coords" (click)="startGame()">
                <span *ngIf="pageConfig.loaders.startGame else loaderBlock"><i class="las la-circle-notch la-2x la-spin"></i></span>
                <ng-template #loaderBlock> <span i18n>Start game</span></ng-template>
              </button>
            </div>
            <div class="row text-center mt-3" *ngIf="activeGame && activeGame.partner && activeGame.partner.isExclusiveBranded">
              <div class="col-12 text-center fi-text-bold">
                <img [src]="activeGame.partner.isWhiteLabel ? activeGame.partner.logo  :'./assets/images/pb.png'" width="90" alt="">
              </div>
            </div>
          </ng-template>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- End of Step 2 -->

<div class="ver">2.1.6.5.a {{browser | json}}</div>
