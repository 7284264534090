<div id="museumBg1">
  <div class="overlay">
    <div class="container p-3">
      <!-- Header -->
      <div class="row">
        <div class="col-6">
          <img src="./assets/images/logo_b.png" width="120" alt="">
        </div>
       
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <h2 class="text-purple fs-bold fi-text-black">Explore the hidden gems of Buda Castle with Sherlock! </h2>
   <!--        <h6 class="text-purple">Flinkit is an incredibly exciting city game that involves finding hidden clues and solving mysteries with your friends. The game is played on your mobile phone and requires internet access.
          </h6> -->
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-12 text-center">
          <h4 class="text-yl fi-text-bold fw-bold text-turqoise">What to expect?</h4>
        </div>
        <div class="col-12 pb-5">
          <swiper #usefulSwiper [config]="swiperConfig">
            <div class="swiper-wrapper align-items-center">
              <!-- Swiper -->
              <div class="swiper-slide text-center">
                <img src="./assets/images/v4/map.png" width="100" alt="">
              
                <h5 class="fi-text-bold mt-3">The game</h5>
                  <p class="mb-4">Flinkit is a super exciting city game that involves finding hidden clues and solving mysteries with your friends. The game runs on your mobile phone and requires internet access.</p>
                </div>
              <!-- End of Swiper -->
               <!-- Swiper -->
               <div class="swiper-slide text-center">
                <img src="./assets/images/v4/vaze.png" width="100" alt="">
                <h5 class="fi-text-bold mt-3">Points</h5>
                <p class="mb-4">A 90-minute long, super fun scavenger hunt that allows you to discover the most iconic parts of Budapest.</p>
              </div>
              <!-- End of Swiper -->
               <!-- Swiper -->
               <div class="swiper-slide text-center">
                <img src="./assets/images/v4/ticket.png" width="100" alt="">
                <h5 class="fi-text-bold mt-3">Start the game</h5>
                <p class="mb-4">Select the game and make the payment via Stripe. After the payment, you will receive an email with the game activation link, as well as the details and location of the game's starting point. Adventure awaits!</p>
              </div>
              <!-- End of Swiper -->
           
              
            </div>
            <div class="swiper-pagination purple"></div>
          </swiper>
        </div>
    
       
      </div>
      <div class="row" *ngIf="(partnerGames && partnerGames.length && !loaders) else loadingBlock">
       
        <div class="col-6 d-flex align-items-center">
          <button class="btn btn-purple" (click)="changePlayers(false)">-</button>
          <div><h5 class="mb-0 mx-3 text-black">{{checkoutObj.numberOfPlayers}}</h5></div>
          <button class="btn btn-purple" (click)="changePlayers(true)">+</button>
          <!-- <div class="input-group mb-3">
            <input type="number" min="2" (change)="changedPlayerNumber()" [(ngModel)]="checkoutObj.numberOfPlayers" class="form-control" placeholder="Number of players" aria-label="Recipient's username" aria-describedby="basic-addon2">
            <span class="input-group-text" id="basic-addon2">players</span>
          </div> -->
      
        </div>
        <div class="col-6 text-dark ps-0 ms-0 text-bold" style="font-size: 0.9rem;">
          Select the number of players
        </div>
      
        <div class="col-12 mt-3">
          <div class="card mb-3 shadow-lg" style="border-radius: 20px;" *ngFor="let game of partnerGames; let i = index;" (click)="selectTour(game)">
            <div class="card-body">
              <h5 class="mb-2 d-flex align-items-center">Beat Sherlock in the Buda Castle</h5> 
              <p class="text-muted fi-text-08">A 90-minute long super fun scavenger hunt discovering the most iconic part of Budapest.</p>

              <div class="d-flex align-items-center">
                <h6 class="fi-text-bold d-flex align-items-center mr-3" *ngIf="checkoutObj.numberOfPlayers>0"><img src="./assets/images/v3/money.svg" width="25"> &nbsp;{{game.price*checkoutObj.numberOfPlayers}}€</h6>
                <h6 class="fi-text-bold d-flex align-items-center"><img src="./assets/images/v3/star.svg" width="25"> &nbsp;4.8</h6>
              </div>

              <button class="btn btn-primary w-100 mt-2" style="background-color: #644E9C; border-color: #644E9C; font-weight: bold;">Checkout</button>
       

            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12  text-center">
          <p class="pb-0 text-white mb-0"><small class="text-white">Contact us</small></p>
          <p class="pb-2 text-white"><small><a href="mailto:help@flinkit.io" class="text-white">help@flinkit.io</a></small></p>
        </div>
          <div class="col-12 pb-5 text-center">
            <p class="pb-2 text-white"><small>The game only supports Chrome or Safari mobile browsers.</small></p>
          </div>
      </div>

     

      

      <ng-template #loadingBlock>
        <div class="row mt-5">
          <div class="col-12 text-center text-white">
            <i class="las la-circle-notch la-2x la-spin"></i>
            <h6 class="mt-2">Loading payment</h6>
          </div>
        </div>
      </ng-template>
     
  
     
    </div>

  </div>
</div>


<!-- <div id="museumBg" *ngIf="partner">
  <div class="d-flex justify-content-center text-center pt-5 w-100">
    <img [src]="partner  && partner.customLogo ? partner.customLogo : './assets/images/museums/logo_b.png'" width="180" alt="">
  </div>
  <div class="museum-leaderboard p-3">
    <div class="leaderboard">
      <div class="row mt-3">
        <div class="col-12 text-center">
          <h4 class="fi-text-black mb-1">{{localeService.getTrans('Available interactive games')}}</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-center">
          <div class="fi-text-black mb-1">{{localeService.getTrans('Switch language')}}</div>
       
        </div>
        <div class="col-6 offset-3 text-center mt-2 mb-2">
          <img src="https://flinkitother.blob.core.windows.net/images/hu.png" (click)="switchLanguage('hu')" class="mr-3" width="40">
          <img src="https://flinkitother.blob.core.windows.net/images/uk.png" (click)="switchLanguage('en')" class="mr-3" width="40">
          <img src="https://flinkitother.blob.core.windows.net/images/de.png" (click)="switchLanguage('de')" width="40">
         
        </div>
        
      </div>
      
      <div class="row mt-3" *ngIf="partnerGames">
        <div class="col-12">
          <div class="fi-result-row p-3 shadow d-flex align-items-center mb-2" *ngFor="let game of partnerGames; let i = index;" >
            <div class="text-left w-100">
              <h4 class="mb-2 fi-text-bold d-flex align-items-center">{{game.name}}</h4> 
              <h6>💵 &nbsp;{{partner.currency}}&nbsp;{{game.price}}<span *ngIf="game.length">&nbsp;· ⏱ &nbsp;{{game.length}}&nbsp;<span i18n>mins</span></span></h6>
              <p class="text-muted fi-text-08">{{game.description}}</p>
              <button class="btn btn-block btn-primary w-100" [disabled]="loaders" (click)="checkout(game)" [style.border-color]="partner ? partner.color : null" [style.backgroundColor]="partner ? partner.color : null"><span i18n>Purchase</span>&nbsp;({{partner.currency}}&nbsp;{{game.price}})</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 pb-5">
        <div class="col-12 text-center">
          <img  src="./assets/images/pbe.png" width="80" alt="">
          <p class="p-2 text-muted"><small i18n>A játék kizárólag Chrome és <br> Safari böngészőkben játszható.</small></p>

        </div>
      </div>
    </div>
   
  </div>
</div> 
 -->