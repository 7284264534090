import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalkthroughModalComponent } from './walkthroughModal.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    NgxUsefulSwiperModule,
    RouterModule,
    HttpClientModule,
    NgbModalModule
  ],
  declarations: [WalkthroughModalComponent],
  exports: [WalkthroughModalComponent]
})
export class WalkthroughModalModule { }
