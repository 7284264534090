import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  locale = 'en';
  translations = {
    
      "Hall of fame":{
         "en":"Hall of fame",
         "de":"Ruhmeshalle",
         "hu":"Eredmények",
         "nl":"Eregalerij",
         "fr":"Palmarès"
      },
      "Maximum number of players reached":{
         "en":"Maximum number of players reached",
         "de":"Maximale Spieleranzahl erreicht",
         "hu":"A maximális játékos szám elérve",
         "nl":"Maximaal aantal spelers bereikt",
         "fr":"Nombre de joueurs maximum atteint"
      },
      "Image successfully uploaded":{
         "en":"Image successfully uploaded",
         "de":"Bild erfolgreich hochgeladen",
         "hu":"Kép sikeresen feltöltve",
         "nl":"Afbeelding succesvol geüpload",
         "fr":"Image téléchargée avec succès"
      },
      "You can throw a bomb on a random player.":{
         "en":"You can throw a bomb on a random player. This means that a randomly selected player gets half a point for a correct answer to the next question.",
         "de":"Sie können eine Bombe per Zufallsgenerator werfen.",
         "hu":"Bombát dobhatsz egy véletlenszerűen kiválasztott játékosra. A bomba hatása, hogy a következő helyes válasz esetén a játékos csupán fele annyi pontot szerez.",
         "nl":"Je kunt een bom op een willekeurig team gooien.",
         "fr":"Vous pouvez lancer une bombe sur un joueur au hasard."
      },
      "You just activated the bomb perk.":{
         "en":"You just activated the bomb perk.",
         "de":"Du hast gerade den Effekt \"Bombe\" aktiviert.",
         "hu":"Aktiváltad a bombát!",
         "nl":"Je hebt zojuist de bom geactiveerd.",
         "fr":"Vous venez d'activer l'effet bombe."
      },
      "Bomb perk active. Half points at next question.":{
         "en":"You just activated the bomb perk.",
         "de":"Bombenvorteil aktiv. Halbe Punkte bei der nächsten Frage.",
         "hu":"Bomba aktív! Fél pont a következő kérdésnél.",
         "nl":"Bom actief. Halve punten bij volgende vraag.",
         "fr":"Effet bombe activé. Moitié des points à la prochaine question."
      },
      "No other players to use this perk on.":{
         "en":"No other players to use this perk on.",
         "de":"Keine anderen Spieler, bei denen dieser Effekt verwendet werden kann.",
         "hu":"Nincs más játékos a játékban, akin alkalmazni tudnád ezt a funkciót.",
         "nl":"Geen andere spelers om deze tool te gebruiken.",
         "fr":"Pas d'autre joueur sur lequel cet effet peut être utilisé."
      },
      "Freeze":{
         "en":"Freeze",
         "de":"Einfrieren",
         "hu":"Fagyasztás",
         "nl":"Bevriezen",
         "fr":"Geler"
      },
      "Free":{
         "en":"Free",
         "de":"Kostenlos",
         "hu":"Ingyenes",
         "nl":"Gratis",
         "fr":"Gratuit"
      },
      "Bomb":{
         "en":"Bomb",
         "de":"Bomb",
         "hu":"Bomba",
         "nl":"Bom",
         "fr":"Bombe"
      },
      "Blank map":{
         "en":"Blank map",
         "de":"Leere Karte",
         "hu":"Vaktérkép",
         "nl":"Lege kaart",
         "fr":"Carte vide"
      },
      "Photo gallery":{
         "en":"Take photos",
         "de":"Fotogallerie",
         "hu":"Készíts fotót",
         "nl":"Fotogalerij",
         "fr":"Photos"
      },
      "freeze":{
         "en":"Freeze",
         "de":"Einfrieren",
         "hu":"Fagyasztás",
         "nl":"Bevriezen",
         "fr":"Geler"
      },
      "bomb":{
         "en":"Bomb",
         "de":"Bomb",
         "hu":"Bomba",
         "nl":"Bom",
         "fr":"Bombe"
      },
      "photo":{
         "en":"Take photos",
         "de":"Fotogallerie",
         "hu":"Fotó galéria",
         "nl":"Foto",
         "fr":"Photos"
      },
      "map_tap":{
         "en":"Teleport",
         "de":"Teleport",
         "hu":"Teleport",
         "nl":"Teleporteer",
         "fr":"Teleport"
      },
      "Freeze perk will freeze all other players for 30 seconds.":{
         "en":"Freeze perk will freeze all other players for 30 seconds.",
         "de":"Freeze Perk friert alle anderen Spieler für 30 Sekunden ein.",
         "hu":"A fagyasztás funkció 30 másodpercig fagyasztja a többi játékost.",
         "nl":"Dit bevriest alle andere teams gedurende 30 seconden.",
         "fr":"L'effet Congel' fige 30 secondes tous les autres joueurs."
      },
      "You can take photos during the game.":{
         "en":"You can take photos during the game.",
         "de":"Sie können während des Spiels Fotos machen.",
         "hu":"Fotókat tudsz készíteni a játék során.",
         "nl":"Tijdens het spel kun je foto's maken.",
         "fr":"Vous pouvez prendre des photos pendant le jeu."
      },
      "You just activated the photo perk.":{
         "en":"You just activated the photo perk.",
         "de":"Sie haben gerade den Foto Effekt eingesetzt",
         "hu":"Aktiváltad a fotó funkciót!",
         "nl":"Je hebt zojuist de foto tool geactiveerd.",
         "fr":"Vous venez d'activer l'effet photo."
      },
      "Blank map perk is active for 30 seconds.":{
         "en":"Blank map perk is active for 30 seconds.",
         "de":"Der Spezialeffekt „Leere Karte“ ist 30 Sekunden lang aktiv.",
         "hu":"Vaktérkép aktív 30 másoedpercig.",
         "nl":"Opengespat ei is 30 seconden actief.",
         "fr":"Effet carte vide activé 30 secondes"
      },
      "It will change the map style to a blank map for other players for 30 seconds.":{
         "en":"It will change the map style to a blank map for other players for 30 seconds.",
         "de":"Der Kartenstil wird für andere Spieler 30 Sekunden lang in eine leere Karte geändert.",
         "hu":"A térkép stílusát 30 másodpercig vaktérképpé változtatja.",
         "nl":"Deze tool verandert de kaartstijl gedurende 30 seconden in een blanco kaart voor andere teams.",
         "fr":"La carte apparaitra vide 30 secondes pour les autres joueurs."
      },
      "PIN to join the game: ":{
         "en":"PIN to join the game: ",
         "de":"PIN, um dem Spiel beizutreten:  ",
         "hu":"Játék PIN",
         "nl":"PIN om mee te doen aan het spel",
         "fr":"PIN pour se connecter au jeu : "
      },
      "Position updated!":{
         "en":"Position updated!",
         "de":"Position aktualisiert.",
         "hu":"Pozíció frissítve",
         "nl":"Positie bijgewerkt!",
         "fr":"Position mise à jour !"
      },
      "What is the goal of the game?":{
         "en":"What is the goal of the game?!",
         "de":"Was ist das Ziel des Spiels?",
         "hu":"Mi a játék célja?",
         "nl":"Wat is het doel van het spel?",
         "fr":"Quel est le but du jeu ?"
      },
      "To collect all the story points":{
         "en":"To collect all the story points",
         "de":"Erreiche alle Wegpunkte und gewinne Punkte.",
         "hu":"Valamennyi kérdés megválaszolása",
         "nl":"Om alle verhaalpunten te verzamelen",
         "fr":"Trouvez tous les marqueurs et gagnez plein de points !"
      },
      "To wait until the time is up":{
         "en":"To wait until the time is up",
         "de":"Warte bis die Zeit um ist.",
         "hu":"Megvárni ameddig letelik az idő",
         "nl":"Om te wachten tot de tijd om is",
         "fr":"Attendre la fin du jeu"
      },
      "To stay outside the battle circle":{
         "en":"To stay outside the battle circle",
         "de":"Innerhalb des Kreises (der Battle Arena) bleiben. ",
         "hu":"Kimaradni a harci körből",
         "nl":"Om buiten de strijdcirkel te blijven",
         "fr":"Rester en dehors du cercle"
      },
      "Don't forget to use the 'Refresh my position' button regularly to refresh your current location in the game.":{
         "en":"Don't forget to use the 'Refresh my position' button regularly to refresh your current location in the game.",
         "de":"Schaltfläche \"Position aktualisieren\" regelmässig klicken, um Standort im Spiel zu aktualisieren.",
         "hu":"Ne nefejtsd el haszánli a 'Pozícióm frissítése' gombot, mivel ezzel frissíted helyzetedet a térképen.",
         "nl":"Vergeet niet regelmatig de knop 'Vernieuw mijn positie' te gebruiken om je huidige locatie in het spel te vernieuwen.",
         "fr":"N'oubliez pas d'utiliser régulièrement le bouton Actualiser ma position pour rafraîchir votre position !"
      },
      "Hurry up, only ":{
         "en":"Hurry up, only ",
         "de":"Beeilung, nur ",
         "hu":"Siess, már csak ",
         "nl":"Schiet op, er resteren slechts",
         "fr":"Vite, il reste "
      },
      " minutes left!":{
         "en":" minutes left!",
         "de":" Minuten übrig!",
         "hu":" perc maradt! ",
         "nl":" minuten!",
         "fr":"minutes !"
      },
      "Got it":{
         "en":"Got it",
         "de":"Verstanden!",
         "hu":"Értettem ",
         "nl":"Ik snap het",
         "fr":"Compris !"
      },
      "Backpack item":{
         "en":"Backpack item",
         "de":"Spezialobjekt für Rucksack",
         "hu":"Hátizsták tárgy ",
         "nl":"Rugzakartikel",
         "fr":"Objet du sac à dos"
      },
      "This item may help you to solve the final riddle.":{
         "en":"This item may help you to solve the final riddle.",
         "de":"Dieses Objekt kann helfen, das letzte Rätsel zu lösen.",
         "hu":"A tárgy segítségedre lehet megoldani a végső rejtélyt ",
         "nl":"Dit item kan je helpen om het laatste raadsel op te lossen.",
         "fr":"Cet objet peut vous aider à résoudre l'énigme finale."
      },
      "Back":{
         "en":"Back",
         "de":"Zurück",
         "hu":"Vissza",
         "nl":"Annuleren",
         "fr":"Retour"
      },
      "Next":{
         "en":"Next",
         "de":"Nächste",
         "hu":"Tovább",
         "nl":"Volgende",
         "fr":"Suivant"
      },
      "Congrats! You've activated the safehouse! Now find it on the map and reach it before it's too late!":{
         "en":"Congrats! You've activated the safehouse! Now find it on the map and reach it before it's too late!",
         "de":"Herzlichen Glückwunsch! Das Safehouse wurde aktiviert. Lokalisiere es auf der Karte und erreiche es, bevor es zu spät ist.",
         "hu":"Gratulálunk! Aktiváltad a \"safe house\"-t. Most keresd meg a térképen és érd el a lehető leggyorsabban!",
         "nl":"Proficiat! Je hebt het safehouse geactiveerd! Vind het nu op de kaart en bereik het voordat het te laat is!",
         "fr":"Félicitations, vous avez activé la Safehouse ! Trouvez-la sur la carte et allez-y avant qu'il ne soit trop tard !"
      },
      " is a fellow player in the musesum who scored ":{
         "en":" is a fellow player in the musesum who scored ",
         "de":" ist MitspielerIn im Museum und hat gepunktet ",
         "hu":" egy másik játékos a múzeumban, aki elért ",
         "nl":" is een medespeler. Die scoorde ",
         "fr":"est un autre joueur dans le musée qui a obtenu"
      },
      " points so far.":{
         "en":" points so far.",
         "de":" Punkte bisher.",
         "hu":" pontot eddig.",
         "nl":" punten tot nu toe.",
         "fr":"points jusqu'ici"
      },
      " The player is still in the museum!":{
         "en":" The player is still in the museum!",
         "de":" Der Spieler ist immer noch im Museum!",
         "hu":" A játékos jelenleg is aktív.",
         "nl":"Het team nog steeds aan het spelen!",
         "fr":"Le joueur est encore dans le musée !"
      },
      "You don't have enough points to use this perk.":{
         "en":"You don't have enough points to use this perk.",
         "de":"Zu wenig Punkte, um Spezialeffekt zu nutzen.",
         "hu":"Nincs elég pontod, hogy használd!",
         "nl":"Je hebt niet genoeg punten om deze tool te gebruiken.",
         "fr":"Vous n'avez pas assez de points pour utiliser cet effet."
      },
      "Use perk":{
         "en":"Use perk",
         "de":"Spezialeffekt einsetzen",
         "hu":"Használom",
         "nl":"Gebruik tool",
         "fr":"Utiliser un effet"
      },
      "Double perk is active. You will receive doupled points at the next question if your answer is correct.":{
         "en":"Double perk is active. You will receive doupled points at the next question if your answer is correct.",
         "de":"Doppler-Effekt ist aktiv. Bei richtiger Beantwortung der nächsten Frage gibt es doppelte Punktzahl.",
         "hu":"A duplázás aktív. Dupla pontot kapsz, ha helyesen válaszolsz!",
         "nl":"Verdubbelaar is actief. Je krijgt dubbele punten bij de volgende vraag als je antwoord juist is.",
         "fr":"Effet-Double est activé. Vous doublez des points à la prochaine question si votre réponse est correcte."
      },
      "Freeze perk is active. You frozed all other players for 30 seconds.":{
         "en":"Freeze perk is active. You froze all other players for 30 seconds.",
         "de":"Freeze Effekt ist aktiv. Die anderen Spieler wurden für 30 Sekunden eingefroren.",
         "hu":"Fagyasztás aktív. Lefagyasztottad az ellenfeleidet 30 másodpercre.",
         "nl":"Bevriezer is actief. Je bevriest alle andere teams gedurende 30 seconden.",
         "fr":"Effet Congel' est activé. Vous avec figé tous les autres joueurs pendant 30 secondes."
      },
      "Do you really want to end the game?":{
         "en":"Do you really want to end the game?",
         "de":"Soll das Spiel wirklich beendet werden?",
         "hu":"Biztosan szeretnéd befejezni a játékot?",
         "nl":"Wil je het spel echt beëindigen?",
         "fr":"Vous voulez vraiment quitter le jeu ?"
      },
      "You cannot rejoin in the game if you hit 'End Game'":{
         "en":"You cannot rejoin in the game if you hit 'End Game'",
         "de":"Das Spiel kann nicht wieder betreten werden, wenn \"Spiel beenden\" geklickt wird.",
         "hu":"A játék véglegesen befejeződik a 'Játék befejezése' gombra kattintva",
         "nl":"Je kunt dit niet ongedaan maken.",
         "fr":"Vous ne pourrez plus rejoindre le jeu si vous cliquez sur Terminer le jeu"
      },
      "End Game":{
         "en":"End Game",
         "de":"Spiel beenden",
         "hu":"Játék befejezése",
         "nl":"Spel beëindigen",
         "fr":"Terminer le jeu"
      },
      "This QR code has been activated already":{
         "en":"This QR code has been activated already",
         "de":"Dieser QR-Code wurde bereits aktiviert",
         "hu":"Ez a QR kód már korábban használva volt",
         "nl":"Deze QR-code is al geactiveerd",
         "fr":"Ce code QR a déjá été activé. "
      },
      "Do you really want to launch the game?":{
         "en":"Do you really want to launch the game?",
         "de":"Spiel jetzt starten?",
         "hu":"Biztosan szeretnéd elindítani a játkot?",
         "nl":"Wil je het spel echt starten?",
         "fr":"Vous voulez vraiment lancer le jeu ?"
      },
      "The game will start for every other players by clicking \"Start game\"":{
         "en":"The game will start for every other players by clicking \"Start game\"",
         "de":"Das Spiel startet für alle SpielerInnen nach Klick auf \"Spiel starten\"",
         "hu":"A játék a \"Játék indítása\" gomb lenyomásával indul",
         "nl":"Zodra je klikt op \"Spel starten\", zal het spel voor iedereen van start gaan.",
         "fr":"Le jeu commencera pour tous les autres joueurs en cliquant sur Commencer le jeu"
      },
      "PIN error":{
         "en":"PIN error",
         "de":"PIN-Fehler",
         "hu":"PIN hiba",
         "nl":"PIN-fout",
         "fr":"PIN erroné"
      },
      "This PIN has expired":{
         "en":"This PIN has expired",
         "de":"Diese PIN ist abgelaufen",
         "hu":"A PIN lejárt",
         "nl":"Deze pincode is verlopen",
         "fr":"Ce PIN a expiré"
      },
      "Wrong PIN":{
         "en":"Wrong PIN",
         "de":"Falsche PIN",
         "hu":"Hibás PIN",
         "nl":"Verkeerde pincode",
         "fr":"Mauvais PIN"
      },
      "There is no game with this PIN":{
         "en":"There is no game with this PIN",
         "de":"Es gibt ken Spiel zu dieser PIN",
         "hu":"Nem találtunk játékot ezzel a PIN-el",
         "nl":"Er is geen spel met deze pincode",
         "fr":"Il n'y a pas de jeu lié à ce PIN"
      },
      "Cannot access location":{
         "en":"Cannot access location",
         "de":"Kann Standort nicht abrufen",
         "hu":"Lokáció letiltva",
         "nl":"Geen toegang tot locatie",
         "fr":"Impossible d'accéder à la localisation"
      },
      "You need to grant access to the browser to start the game.":{
         "en":"You need to grant access to the browser to start the game.",
         "de":"Sie müssen den Zugriff auf den Browser gewähren, um das Spiel zu starten.",
         "hu":"A lokáció engedélyezése szükséges a játékhoz.",
         "nl":"Je moet toegang verlenen tot de browser om het spel te starten.",
         "fr":"Vous devez autoriser l'accès au navigateur pour démarrer le jeu."
      },
      "You are standing outside the battle circle. You've lost ":{
         "en":"You are standing outside the battle circle. You've lost ",
         "de":"Außerhalb des Kreises! Strafscore: ",
         "hu":"A harci körön kívül állsz. Vesztettél: ",
         "nl":"Je staat buiten de spelcirkel. Je verloor",
         "fr":"Vous êtes en dehors du cercle. Vous avez perdu"
      },
      " points!":{
         "en":" points!",
         "de":" Punkte",
         "hu":" pont.",
         "nl":" punten!",
         "fr":"points !"
      },
      "Well, there are no markers nearby.":{
         "en":"Well, there are no markers nearby.",
         "de":"Keine Wegpunkte in der Nähe.",
         "hu":"Nincs kérdés a közeledben",
         "nl":"Nou, er zijn geen check-points in de buurt.",
         "fr":"Pas de marqueurs dans les environs."
      },
      "Correct answer":{
         "en":"Correct answer",
         "de":"Richtige Antwort",
         "hu":"Helyes válasz",
         "nl":"Goed antwoord",
         "fr":"Bonne réponse "
      },
      "Correct answer! You have earned ":{
         "en":"Correct answer! You have earned ",
         "de":"Richtige Antwort! Du erhältst ",
         "hu":"Helyes válasz! Jutalmad: ",
         "nl":"Goed antwoord! Je hebt verdiend: ",
         "fr":"Bonne réponse! Vous avez obtenu"
      },
      "You have earned ":{
         "en":"You have earned ",
         "de":"Du erhältst ",
         "hu":"Jutalmad: ",
         "nl":"Je hebt verdiend: ",
         "fr":"Vous avez obtenu"
      },
      "The correct answer would be: ":{
         "en":"The correct answer would be: ",
         "de":"Die richtige Antwort wäre: ",
         "hu":"A helyes válasz: ",
         "nl":"Het juiste antwoord was: ",
         "fr":"La bonne réponse serait : "
      },
      "The time is up":{
         "en":"The time is up",
         "de":"Die Zeit ist abgelaufen.",
         "hu":"Lejárt az idő",
         "nl":"De tijd is op",
         "fr":"Le temps est terminé."
      },
      "A new item has been added to your backpack!":{
         "en":"A new item has been added to your backpack!",
         "de":"Ein neues Objekt wurde in den Rucksack gelegt.",
         "hu":"Új elem került a hátizsákodba",
         "nl":"Er is een nieuw item aan je rugzak toegevoegd!",
         "fr":"Un nouvel objet a été placé dans votre sac à dos !"
      },
      "Incorrect answer":{
         "en":"Incorrect answer",
         "de":"Falsche Antwort",
         "hu":"Helytelen válasz",
         "nl":"Fout antwoord",
         "fr":"Mauvaise réponse"
      },
      "Try again":{
         "en":"Try again",
         "de":"Nichmals versuchen",
         "hu":"Próbáld újra",
         "nl":"Probeer het opnieuw",
         "fr":"Réessayez"
      },
      "Start game":{
         "en":"Start game",
         "de":"Spiel starten",
         "hu":"Játék indítása",
         "nl":"Spel starten",
         "fr":"Commencer le jeu"
      },
      "Getting location data takes more time than usual. Please wait for the map to initialize":{
         "en":"Getting location data takes more time than usual. Please wait for the map to initialize",
         "de":"Den Standort abzurufen dauert länger, als üblich. Bitte warten, bis Karte gestartet wird.",
         "hu":"A térkép betöltése a szokásosnál több időt vesz igénybe. Kérjük várj.",
         "nl":"Locatiebepaling duurt langer dan voorzien. Wacht tot de kaart is geïnitialiseerd.",
         "fr":"Le téléchargement des données de localisation prend du temps. Merci de patienter."
      },
      "Location found, you can join to the game.":{
         "en":"Location found, you can join to the game.",
         "de":"Standort gefunden. Spiel beitreten.",
         "hu":"Aktuális pozíció betöltve.",
         "nl":"Locatie gevonden, je kan het spel opstarten",
         "fr":"Emplacement trouvé, vous pouvez commencer le jeu."
      },
      "Available interactive games":{
         "en":"Available interactive games",
         "de":"Verfügbare interaktive Spiele",
         "hu":"Elérhető játékok",
         "nl":"Beschikbare spelconcepten",
         "fr":"Jeux interactifs disponibles"
      },
      "Available games":{
         "en":"Available games",
         "de":"Verfügbare Spiele",
         "hu":"Elérhető játékok",
         "nl":"Beschikbare spelconcepten",
         "fr":"Jeux disponibles"
      },
      "Switch language":{
         "en":"Change language",
         "de":"Sprache ändern",
         "hu":"Nyelv váltása",
         "nl":"Taal wijzigen",
         "fr":"Changer la langue"
      },
      "Now we will redirect you to the payment page.":{
         "en":"Now we will redirect you to the payment page.",
         "de":"Die Weiterleitung zu Bezahlseite erfolgt nun.",
         "hu":"A tovább gomb lenyomásával továbbirányítunk a biztonságos Stripe fizetési oldalra. A bankkártyás fizetést követően a játékhoz szükséges információkat email formájában fogod megkapni.",
         "nl":"We zullen je omleiden naar de betaalpagina",
         "fr":"Vous allez être redirigé vers la page de paiement."
      },
      "Checkout":{
         "en":"Checkout",
         "de":"Zur Kasse",
         "hu":"Fizetés",
         "nl":"Betalen",
         "fr":"Passer à la caisse"
      },
      "Confirm":{
         "en":"Confirm",
         "de":"Bestätigung",
         "hu":"Rendben",
         "nl":"Bevestigen",
         "fr":"Confirmer"
      },
      "Not supported browser":{
         "en":"Not supported browser",
         "de":"Nicht unterstützter Browser",
         "hu":"Nem támogatott böngésző",
         "nl":"Niet ondersteunde browser ",
         "fr":"Navigateur non compatible"
      },
      "The game only supports Chrome or Safari mobile browsers. ":{
         "en":"The game only supports Chrome or Safari mobile browsers. ",
         "de":"Das Spiel unterstützt nur CHROME und SAFARI Mobile Browser.",
         "hu":"A játék kizárólag Chrome vagy Safari böngészőkben játszható. ",
         "nl":"Het spel ondersteunt alleen mobiele Chrome- of Safari-browsers. ",
         "fr":"Le jeu est compatible uniquement avec Chrome ou Safari."
      },
      "Double your points at the next question":{
         "en":"Double your points at the next question",
         "de":"Verdoppeln Sie Ihre Punkte bei der nächsten Frage",
         "hu":"A következő kérdés dupla vagy semmi ",
         "nl":"Verdubbel je punten op de volgende vraag",
         "fr":"Doublez vos points à la prochaine question"
      },
      "You can freeze all players for 30 seconds":{
         "en":"You can freeze all players for 30 seconds",
         "de":"Sie können alle Spieler für 30 Sekunden einfrieren",
         "hu":"Lefagyaszthatod az összes játékost 30 másodpercre ",
         "nl":"Je kunt alle andere teams 30 seconden bevriezen",
         "fr":"Vous pouvez geler les autres joueurs pendant 30 secondes"
      },
      "Be aware":{
         "en":"Be aware",
         "de":"Sei vorsichtig",
         "hu":"Figyelem",
         "nl":"Pas op",
         "fr":"Attention"
      },
      "Please note that the game only supports Chrome or Safari browsers. Do you want to proceed?":{
         "en":"Please note that the game only supports Chrome and Safari browsers. Do you want to proceed?",
         "de":"Bitte beachten Sie, dass das Spiel nur die Browser Chrome und Safari unterstützt. Willst du fortfahren?",
         "hu":"Felhívjuk a figyelmedet, hogy a játék csak a Chrome és a Safari böngészőket támogatja. Biztosan akarod folytatni?",
         "nl":"Houd er rekening mee dat het spel alleen Chrome- en Safari-browsers ondersteunt. Wil je doorgaan?",
         "fr":"Veuillez noter que le jeu est compatible uniquement avec Chrome ou Safari. Vous voulez continuer ? "
      },
      "Yes":{
         "en":"Yes",
         "de":"Jawohl",
         "hu":"Igen",
         "nl":"Ja",
         "fr":"Oui"
      },
      "Felfedezős kalandjáték a mobilodon itt és most.":{
         "en":"Treasure hunt on your mobile! Here and now!",
         "de":"Schatzsuche auf Ihrem Handy! Hier und Jetzt!",
         "hu":"Felfedezős kalandjáték a mobilodon itt és most.",
         "nl":"Schattenjacht op je mobiel toestel! Hier en nu!",
         "fr":"Une chasse au trésors sur votre smartphone ? C'est par ici !"
      },
      "Játékok":{
         "en":"Games",
         "de":"Spiele",
         "hu":"Játékok",
         "nl":"Spelconcepten",
         "fr":"Jeux"
      },
      "A legteljesebb játékélményhez Chrome és Safari böngészők használata ajánlott.":{
         "en":"The game only supports Chrome or Safari mobile browsers. ",
         "de":"Das Spiel unterstützt nur CHROME und SAFARI Mobile Browser.",
         "hu":"A legteljesebb játékélményhez Chrome és Safari böngészők használata ajánlott. ",
         "nl":"Het spel ondersteunt alleen mobiele Chrome- of Safari-browsers. ",
         "fr":"Le jeu est compatible uniquement avec Chrome ou Safari."
      },
      "Mire számítsak?":{
         "en":"What do I expect?",
         "de":"Was erwarte ich?",
         "hu":"Mire számítsak?",
         "nl":"Wat te verwachten?",
         "fr":"Qu'est-ce qui m'attend ?"
      },
      "A játék":{
         "en":"The game",
         "de":"Das Spiel",
         "hu":"A játék",
         "nl":"Het spel",
         "fr":"Le jeu"
      },
      "A játék során számos izgalmas kérdés és feladat által fedezed fel a helyszínt, és megismered titkait. A játék meglepetéseket is tartogat...":{
         "en":"During the game, you will explore the scene and learn its secrets through a number of exciting questions and tasks. The game also has surprises ...",
         "de":"Während des Spiels erkunden Sie die Szene und lernen ihre Geheimnisse durch eine Reihe spannender Fragen und Aufgaben kennen. Das Spiel hat auch Überraschungen ...",
         "hu":"A játék során számos izgalmas kérdés és feladat által fedezed fel a helyszínt, és ismered meg titkait. A játék meglepetéseket is tartogat...",
         "nl":"Tijdens het spel verken je de scène en leer je de geheimen ervan door middel van een aantal spannende vragen en taken. Het spel heeft ook verrassingen ...",
         "fr":"Au cours du jeu, vous explorerez le terrain et découvrirez ses secrets à travers des questions et des énigmes passionnantes. Plein de surprises vous attendent !"
      },
      "Szerezz minél több pontot! Ehhez a térképen megjelölt helyszínekre kell eljutnod, ill. a kihelyezett QR kódokat beolvasnod.":{
         "en":"Get as many points as possible! To do this, you need to get to the locations marked on the map, or you can scan the posted QR codes.",
         "de":"Holen Sie sich so viele Punkte wie möglich! Dazu müssen Sie zu den auf der Karte markierten Orten gelangen oder Sie können die geposteten QR-Codes scannen.",
         "hu":"Szerezz minél több pontot! Ehhez a térképen megjelölt helyszínekre kell eljutnod, ill. a kihelyezett QR kódokat beolvasnod.",
         "nl":"Scoor zoveel mogelijk punten! Om dit te doen, moet je naar de locaties die op de kaart zijn gemarkeerd, of je kunt de QR-codes scannen.",
         "fr":"Obtenez le plus de points possible ! Pour ça, vous devez vous rendre aux endroits indiqués sur la carte, ou vous pouvez scanner les codes QR affichés."
      },
      "Válaszd ki a játékot és kattints a \"Játék indítása\" gombra, amelyet követően a játék azonnal indul. Az első két feladvány ingyenes, amelyet után online bankkártyás fizetést követően folytathatod azt. Kalandra fel!":{
         "en":"Select the game and click on the \"Start Game\" button, after which the game will start immediately. The first two puzzles are free, and after that, you can continue by making an online payment with your credit card. Adventure awaits!",
         "de":"Wähle das Spiel aus und klicke auf die Schaltfläche \"Spiel starten\", danach beginnt das Spiel sofort. Die ersten beiden Rätsel sind kostenlos und danach kannst du sie durch eine Online-Zahlung mit deiner Kreditkarte fortsetzen. Abenteuer wartet!",
         "hu":"Válaszd ki a játékot és kattints a \"Játék indítása\" gombra, ezt követően a játék azonnal indul. Az első két feladvány ingyenes. Ezután online bankkártyás fizetéssel egyenlítsd ki a játék díját, hogy folytathasd. Kalandra fel!",
         "nl":"Kies het spel en klik op de knop \"Start spel\", waarna het spel direct begint. De eerste twee puzzels zijn gratis, en daarna kun je verdergaan door een online betaling met je creditcard te doen. Avontuur wacht!"
      },
      "Pontok":{
         "en":"Points",
         "de":"Punkte",
         "hu":"Pontok",
         "nl":"Punten",
         "fr":"Points"
      },
      "A játék indítása":{
         "en":"Start the game",
         "de":"Starte das Spiel",
         "hu":"A játék indítása",
         "nl":"Start het spel",
         "fr":"Commencer le jeu"
      },
      "Tovább a biztonságos fizetésre":{
         "en":"Secure payment",
         "de":"Sichern Sie weiterhin die Zahlung",
         "hu":"Tovább a biztonságos fizetésre",
         "nl":"Beveiligde betaling",
         "fr":"Paiement sécurisé"
      },
      "Lépj kapcsolatba velünk:":{
         "en":"Contact us:",
         "de":"Kontaktiere uns:",
         "hu":"Lépj kapcsolatba velünk:",
         "nl":"Neem contact met ons op:",
         "fr":"Contact : "
      },
      "Checking location data":{
         "en":"Checking location data",
         "de":"Überprüfung der Standortdaten",
         "hu":"Lokáció adatok frissítése",
         "nl":"Locatiegegevens controleren",
         "fr":"Vérification des données de localisation"
      },
      "Loading game":{
         "en":"Loading game",
         "de":"Spiel lädt",
         "hu":"Betöltés",
         "nl":"Spel laden",
         "fr":"Chargement du jeu"
      },
      "Congrats! You unlocked the last marker!":{
         "en":"Congrats! You unlocked the last marker!",
         "de":"Herzlichen Glückwunsch! Du hast die letzte Markierung freigeschaltet!",
         "hu":"Gratulálunk! Aktiváltad az utolsó pontot!",
         "nl":"Gefeliciteerd! Je hebt het laatste check-point ontgrendeld!",
         "fr":"Félicitations, vous avez débloqué la dernIère énigme !"
      },
      "Splashed egg":{
         "en":"Splashed egg",
         "de":"Gespritztes Ei",
         "hu":"Fröccsenő tojás",
         "nl":"Opengespat ei",
         "fr":"oeuf éclaté"
      },
      "blank_map":{
         "en":"Splashed egg",
         "de":"Gespritztes Ei",
         "hu":"Fröccsenő tojás",
         "nl":"Opengespat ei",
         "fr":"oeuf éclaté"
      },
      "A splashed egg is displayed to players, obscuring the map for 30 seconds.":{
         "en":"A splashed egg is displayed to players, obscuring the map for 30 seconds.",
         "de":"Ein bespritztes Ei wird den Spielern angezeigt und verdeckt die Karte für 30 Sekunden.",
         "hu":"Egy fröccsenő tojás jelenik meg a játékosok térképén, amely akadályozza a látásukat 30 másodpercig.",
         "nl":"De andere teams krijgen een opengespat ei te zien, waardoor hun kaart gedurende 30 seconden wordt verduisterd.",
         "fr":"Un oeuf éclaté apparaît aux joueurs, masquant la carte pendant 30 secondes."
      },
      "Splashed egg perk is active for 30 seconds.":{
         "en":"Splashed egg perk is active for 30 seconds.",
         "de":"Der Perk „Bespritztes Ei“ ist 30 Sekunden lang aktiv.",
         "hu":"A fröccsenő tojás 30 másodpercig aktív.",
         "nl":"Opengespat ei is 30 seconden actief.",
         "fr":"Effet oeuf éclaté activé pendant 30 secondes."
      },
      "Flinkit is a new real world adventure game packed with codes to be cracked, clues to be uncovered, and puzzles to be solved in and outside. Fast, fun and easy to get going.":{
         "en":"The game is a new real world adventure game packed with codes to be cracked, clues to be uncovered, and puzzles to be solved in and outside. Fast, fun and easy to get going.",
         "de":"RallyePlus Outdoor ist ein neuartiges Outdoorspiel, voller Abenteuer und kniffliger Aufgaben. Sie erhalten Punkte für jede gelöste Aufgabe. Das Team mit den meisten Punkten gewinnt.",
         "hu":"A Flinkit egy a valós világban játszódó, mégis online kalandjáték, tele izgalmas fejtörőkkel, ahol nyomokat kell feltárni, és rejtvényeket kell megoldani. Gyors, szórakoztató és élménydús.",
         "nl":"Ludiris Outdoor! is een innovatief spel waarbij je zo secuur mogelijk een aantal vragen dient op te lossen op bepaalde locaties. Speel dit spel tegen elkaar in de stad. Snel, leuk en makkelijk te spelen.",
         "fr":"Flinkit Rallye Outdoor est un nouveau jeu d'aventures à l'extérieur où vous devez déchiffrer des codes, découvrir des indices et résoudre des énigmes. Chaque énigme résolue rapporte des points. L'équipe avec le maximum de points remporte la victoire. "
      },
      "Map tap":{
         "en":"Teleport",
         "de":"Teleport",
         "hu":"Térkép érintés",
         "nl":"Teleportatie",
         "fr":"Toucher la carte"
      },
      "Simulate your position by tapping on the map.":{
         "en":"Simulate your position by tapping on the map.",
         "de":"Simulieren Sie Ihre Position, indem Sie auf die Karte tippen.",
         "hu":"A térképen a megérintett pontra tudsz ugrani, így egy távoli pontot könnyen begyűjthetsz. A funkció 30 mp-ig él.",
         "nl":"Simuleer je positie door op de kaart te tikken.",
         "fr":"Simulez votre position en tapant sur la carte."
      },
      "It allows you to simulate their position by tapping on the map, which allows you to reach remote points for up to 30 seconds. Please wait 5 seconds after you activated this perk, then start tapping on the map.":{
         "en":"It allows you to simulate their position by tapping on the map, which allows you to reach remote points for up to 30 seconds. Please wait 5 seconds after you activated this perk, then start tapping on the map.",
         "de":"Sie können Ihre Position simulieren, indem Sie auf die Karte tippen, was es Ihnen ermöglicht, entfernte Punkte für bis zu 30 Sekunden zu erreichen. Bitte warten Sie 5 Sekunden, nachdem Sie diesen Perk aktiviert haben, und beginnen Sie dann, auf die Karte zu tippen.",
         "hu":"A térképen a megérintett pontra tudsz ugrani, így egy távoli pontot könnyen begyűjthetsz. A funkció 30 mp-ig él. Kérjük várj 5 másodpercet miután aktiváltad ezt a funkciót, majd kezdj el a térképen kattintani.",
         "nl":"Hiermee kun je je positie simuleren door ergens op de kaart te tikken, waardoor je gedurende 30 seconden virtueel tot bij ver afgelegen punten kunt komen. Wacht 5 seconden nadat je deze functie hebt geactiveerd, en begin dan op de kaart te tikken.",
         "fr":"Vous pouvez simuler votre position en tapant sur la carte, ce qui vous permet d'atteindre des endroits éloignés pendant 30 secondes. Veuillez attendre 5 secondes après avoir activé cet effet, puis commencez à taper sur la carte."
      },
      "Take a photo":{
         "en":"Take a photo",
         "de":"Mach ein Foto",
         "hu":"Fotó készítése",
         "nl":"Maak een foto",
         "fr":"Prendre une photo"
      },
      "No image selected":{
         "en":"No image selected",
         "de":"Kein Bild ausgewählt",
         "hu":"Nincs kép kiválasztva",
         "nl":"Geen afbeelding geselecteerd",
         "fr":"Aucune image sélectionnée"
      },
      "Upload image":{
         "en":"Upload image",
         "de":"Bild hochladen",
         "hu":"Kép feltöltése",
         "nl":"Afbeelding uploaden",
         "fr":"Télécharger l'image"
      },
      "You've arrived to the last question":{
         "en":"You've arrived to the last question",
         "de":"Sie sind bei der letzten Frage angelangt",
         "hu":"Megérkeztél az utolsó kérdéshez!",
         "nl":"Je bent aangekomen bij de laatste vraag",
         "fr":"Vous êtes arrivé à la dernière question !"
      },
      "You can unlock questions by approaching them. The points are triggered when you stand in a 10 meters radius.":{
         "en":"You can unlock questions by approaching them. The points are triggered when you stand in a 10 meters radius.",
         "de":"Sie können Fragen freischalten, indem Sie sich ihnen nähern. Die Punkte werden ausgelöst, wenn Sie in einem Umkreis von 10 Metern stehen.",
         "hu":"A pontok akkor aktiválódnak, ha 10 méteres körzetben állsz.",
         "nl":"Je kunt vragen ontgrendelen door check-points te benaderen. De punten worden geactiveerd wanneer je je binnen een straal van 10 meter bevindt.",
         "fr":"Vous débloquez des questions en vous en rapprochant. Les points sont activés lorsque vous vous trouvez dans un rayon de 10 mètres."
      },
      "Please contact your program facilitator":{
         "en":"Please contact your program facilitator",
         "de":"Bitte wenden Sie sich an Ihren Spieladministrator",
         "hu":"Vedd fel a kapcsolatot a játék szervezőjével",
         "nl":"Neem contact op met je spelbeheerder",
         "fr":"Veuillez contacter votre responsable de jeu"
      },
      "Refresh":{
         "en":"Refresh",
         "de":"Aktualisierung",
         "hu":"Frissítés",
         "nl":"Vernieuwen",
         "fr":"Rafraîchir"
      },
      "In order to continue the game we redirect you to the checkout page. After the checkout you will be redirected to the game.":{
         "en":"To continue playing, the purchase of the game is necessary. We will redirect you to the payment interface for this purpose. After a successful payment, we will redirect you back to the game.",
         "de":"In order to continue the game we redirect you to the checkout page. After the checkout you will be redirected to the game.",
         "hu":"A játék folytatásához szükséges annak megvásárlása. Ehhez átirányítunk a fizetési felületre. A sikeres fizetés után pedig visszairányítunk a játékba.",
         "nl":"Om het spel te beëindigen, brengen we je naar de betaalpagina. Na betaling word je terug naar het spel gestuurd.",
         "fr":"Afin de continuer le jeu, l’achat du jeu est nécessaire. Nous vous redirigerons vers l’interface de paiement à cette fin. Après un paiement réussi, nous vous redirigerons vers le jeu."
      },
      "Proceed to checkout":{
         "en":"Proceed to checkout",
         "de":"Proceed to checkout",
         "hu":"Tovább a fizetésre",
         "nl":"Ga naar betaling",
         "fr":"Passer à la caisse"
      },
      "Játék indítása":{
         "en":"Start game",
         "de":"Spiel starten",
         "hu":"Játék indítása",
         "nl":"Start spel",
         "fr":"Démarrer le jeu"
      },
      "Uh oh":{
         "en":"Uh oh!",
         "de":"Uh oh!",
         "hu":"Aiai!",
         "nl":"Uh oh!"
      },
      "Your email address":{
         "en":"Your email address",
         "de":"Your email address",
         "hu":"Email címed",
         "nl":"Jouw mailadres",
         "fr":"Votre adresse e-mail"
      },
      "Enter your email address":{
         "en":"Enter your email address!",
         "de":"Enter your email address",
         "hu":"Kérjük add meg az email címedet",
         "nl":"Geef je mailadres in",
         "fr":"Entrez votre adresse e-mail"
      },
      "OK":{
         "en":"OK",
         "de":"OK",
         "hu":"OK",
         "nl":"OK",
         "fr":"OK"
      },
      "Player name/team name":{
         "en":"Player name/team name",
         "de":"Spielername/Teamname",
         "hu":"Játékosnév/csapatnév",
         "nl":"Spelersnaam/teamnaam",
         "fr":"Nom du joueur/nom de l'équipe"
      },
      "You can unlock questions by finding QR codes in the museum (marked on the map) and scanning them with the 'Scan QR Code' button.":{
         "en":"You can unlock questions by finding QR codes in the event space (marked on the map) and scanning them with the 'Scan QR Code' button.",
         "de":"Sie können Fragen freischalten, indem Sie im Gebäude QR-Codes (auf der Karte markiert) finden und mit der Schaltfläche „QR-Code scannen“ scannen.",
         "hu":"Az egyes kérdéseket a térképen megjelöltük. Keresd meg a QR kódokat és szkenneld be őket.",
         "nl":"Je kunt vragen ontgrendelen door QR-codes te vinden (aangegeven op de kaart) en deze te scannen met de knop 'Scan QR Code'.",
         "fr":"Vous pouvez déverrouiller les questions en trouvant des codes QR dans le musée (marqués sur la carte) et en les scannant avec le bouton Scanner le code QR."
      },
      "Congrats! The game has ended!":{
         "en":"Congrats! The game has ended!",
         "de":"Glückwunsch! Das Spiel ist beendet!",
         "hu":"Gratulálunk! A játék véget ért!",
         "nl":"Gefeliciteerd! Het spel is afgelopen!",
         "fr":"Bravo! Le jeu est terminé !"
      },
      "Reconnect":{
         "en":"Reconnect",
         "de":"Reconnect",
         "hu":"Újracsatlakozás",
         "nl":"Opnieuw verbinden",
         "fr":"Reconnecter"
      },
      Send: {
         en: 'Send',
         de: 'Send',
         hu: 'Küldés',
         nl: 'Send',
         fr: 'Envoyer',
         },
      "Send your message": {
         en: 'Send your message',
         de: 'Send your message',
         hu: 'Üzenet küldése',
         nl: 'Uw bericht',
         fr: 'Envoyez votre message',
         },
     "You can send message to the game master": {
         en: 'You can send message to the game master',
         de: 'You can send message to the game master',
         hu: 'Üzenetet küldhetsz a játékmesternek',
         nl: 'Je kunt een bericht sturen naar de spelmeester',
         fr: 'Vous pouvez envoyer un message au maître du jeu',
         },
         "Text Input": {
          en: 'Text Input',
          de: 'Text Input',
          hu: 'Szöveg bevitel',
          nl: 'Tekstinvoer',
          fr: 'Text Input',
          },
          "text_input": {
            en: 'Text Input',
            de: 'Texteingabe',
            hu: 'Szöveg bevitel',
            nl: 'Tekstinvoer',
            fr: 'Text Input',
            },
            "Somebody froze all the players for 30 seconds.":{
               "en":"Somebody froze all the players for 30 seconds.",
               "de":"Jemand hat alle Spieler für 30 Sekunden eingefroren.",
               "hu":"Valaki 30 másodpercre befagyaszotta az összes játékost.",
               "nl":"Iemand heeft alle teams 30 seconden bevroren.",
               "fr":"Quelqu'un a gelé tous les joueurs pendant 30 secondes."
            },
            "Text snippet sent": {
               "en":"Text snippet sent",
               "de":"Textausschnitt gesendet",
               "hu":"Szöveg elküldve",
               "nl":"Tekstfragment verzonden",
               "fr":"Extrait de texte envoyé"
            },
            "The goal of the game is collect as many points as you can while solve riddles on the map.": {
               "en":"The goal of the game is collect as many points as you can while solving riddles on the map.",
               "de":"Das Ziel des Spiels ist es, so viele Punkte wie möglich zu sammeln, während Sie Rätsel auf der Karte lösen.",
               "hu":"A játék célja, hogy minél több pontot gyűjts, miközben rejtélyeket oldasz meg a térképen.",
               "nl":"Het doel van het spel is om zoveel mogelijk punten te verzamelen terwijl je raadsels op de kaart oplost.",
               "fr":"Le but du jeu est de collecter autant de points que possible tout en résolvant des énigmes sur la carte."
            
            }
   
  };

  constructor() {}

  getTrans(trans) {
    try {
      if (this.translations[trans] && this.translations[trans][this.locale]) {
        return this.translations[trans][this.locale];
      } else {
        return this.translations[trans]['en'];
      }
    } catch {
      console.log('error', trans);
    }
  }
}
