import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperComponent } from 'ngx-useful-swiper';
import { GameService } from 'src/app/services/game.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-walkthroughModal',
  templateUrl: './walkthroughModal.component.html',
  styleUrls: ['./walkthroughModal.component.scss']
})
export class WalkthroughModalComponent implements OnInit {
  @Input() mode = 'page';
  @ViewChild('usefulSwiper',{static: true}) usefulSwiper: SwiperComponent;

  swiperConfig = {
    loop: false,
    slidesPerView: 'auto',
    centeredSlides: false,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };
  game;
  bgColor;
  constructor(private router: Router, private route: ActivatedRoute, private activeModal: NgbModal, public gameService: GameService, private http: HttpClient) {
   
  }

  jumpToGame(inGame?){
    if(inGame){
      this.router.navigate(['/game', {g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p'), inGame: true}], {replaceUrl: true});
    } else{
      if(!this.game.tourId.isStartScreen){
        this.router.navigate(['/game', {g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p') }], {replaceUrl: true});
      } else{
        this.router.navigate(['/start-screen', {g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p') }], {replaceUrl: true});
      }
      //this.router.navigate(['/game', {g: this.route.snapshot.paramMap.get('g'), p: this.route.snapshot.paramMap.get('p')}]);
    }
    
  }

  closeModal(){
    this.activeModal.dismissAll();
  }

  navigateBack() {
    this.router.navigate(['/']);
  }

  getGame(gameId, player){
    return this.http.post(environment.baseUrl + 'games/getGame', {gameId, player} ).subscribe((data: any) => {
     if(data.success){
       this.game = data.data;
       if(this.game.partner && this.game.partner.color){
        this.bgColor = this.game.partner.color;
       }

     }
   }); 
 }

  ngOnInit() {
  
    if(this.route.snapshot.paramMap.get('m') == 'embed'){
      this.mode = 'embed';
    }
    if (!this.route.snapshot.paramMap.get('g') || !this.route.snapshot.paramMap.get('p')) {
      if(this.mode === 'page'){
        this.navigateBack();
      }   
    } 
    if(this.route.snapshot.paramMap.get('g') && this.route.snapshot.paramMap.get('p')){
      this.getGame(this.route.snapshot.paramMap.get('g'), this.route.snapshot.paramMap.get('p'))
    }
   
  }

}
