import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "../../environments/environment";
import { LocationService } from '../services/location.service';
import Swal from "sweetalert2";
import { GameService } from '../services/game.service';
declare var Stripe;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit {
  activeTour;
  stripe;
  checkoutObj = {
    players: 3,
    position: null,
    gameMode: 'competitive',
    indoorMode: false,
    tourPrice: null,
    tour: null
  };
  loaders = {
    getLocation: false,
    startGame: false
  }
  sliderOptions = {
    floor: 1,
    ceil: 40,
    translate: (value: number): string => {
      if(value < this.pageConfig.maxPlayers){
        return value + ' devices';
      } else{
        return this.pageConfig.maxPlayers + '+ devices';
      }
      
    }
  };
  pageConfig = {
    maxPlayers: 40,
    locationError: null,
    center: null
  }
  swiperContent;
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private locationService: LocationService, private ngZone: NgZone, public gameService: GameService) {
    if (this.route.snapshot.paramMap.get('t')) {
        this.getTour(this.route.snapshot.paramMap.get('t'))
        this.refreshPosition();
    } else{
      this.navigateBack();
    }
  }

  refreshPosition(){
    this.loaders.getLocation = true;
    this.checkoutObj.position = null;
    this.locationService.getPosition().then((x: any)=>{
      console.log(x)
      this.ngZone.run(() => {
        this.loaders.getLocation = false;
      if(x.coords){
        console.log(x);
        this.checkoutObj.position = x;
        this.pageConfig.center = [x.coords.longitude, x.coords.latitude];
        this.pageConfig.locationError = null;
        
      } else{
        alert('Location is not available')
      }
      });
    }, (err)=>{
      console.log(err)
      this.ngZone.run(() => {
        this.loaders.getLocation = false;
        this.pageConfig.locationError = err.message;
        this.locationService.dropError();
      });
    })
  }
  setMode(mode){
    
     this.checkoutObj.indoorMode = mode;
    
  }
  checkout(){
   
    if(this.checkoutObj.players < this.pageConfig.maxPlayers){
      
      if(this.gameService.isPartnerGame){
        Swal.fire({
          imageUrl: '../assets/images/walkthrough/3.png',
          title: 'Start',
          text: "Do you want to start the game?",
          showDenyButton: false,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: `Confirm`,
          confirmButtonColor: '#4E3B74',
          cancelButtonText: 'Back'
        }).then((result) => {
          if (result.isConfirmed) {
            this.loaders.startGame = true;
            this.loaders.startGame = true;
            return this.http.post(environment.baseUrl + 'tours/generateTour', {tourId: this.activeTour._id, checkoutObj: this.checkoutObj, partner: this.gameService.partner} ).subscribe((data: any) => {
              console.log(data)
               this.router.navigate(['/success', {g: data.data._id, p: data.data.pin}]);
            }); 
          } 
        })
      } else{
        Swal.fire({
          imageUrl: '../assets/images/walkthrough/3.png',
          title: 'Checkout',
          text: "Now we'll redirect you to the checkout screen.",
          showDenyButton: false,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: `Confirm`,
          confirmButtonColor: '#4E3B74',
          cancelButtonText: 'Back'
        }).then((result) => {
          if (result.isConfirmed) {
            this.loaders.startGame = true;
            this.checkoutObj.tourPrice = this.activeTour.price;
            this.checkoutObj.tour = this.route.snapshot.paramMap.get('t');
            return this.http.post(environment.baseUrl + 'payments/checkoutSession', {checkoutObj: this.checkoutObj} ).subscribe((data: any) => {
              console.log(data)
              this.stripe.redirectToCheckout({ sessionId: data.data.id });
               //this.router.navigate(['/success', {g: data.data._id, p: data.data.pin}]);
            }); 
  
           /*  this.loaders.startGame = true;
            return this.http.post(environment.baseUrl + 'tours/generateTour', {tourId: this.activeTour._id, checkoutObj: this.checkoutObj} ).subscribe((data: any) => {
              console.log(data)
               this.router.navigate(['/success', {g: data.data._id, p: data.data.pin}]);
            });  */
          } 
        })
      }

    } else{
      Swal.fire({
        icon:'info',
        title: 'Contact us',
        text: 'For teams bigger then 30 players contact us for a unique offer at info@flinkit.io',
        showDenyButton: false,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: `Got it`,
        confirmButtonColor: '#4E3B74',
        cancelButtonText: 'Close'
      })
    }
    
   
  }

  setPosition(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/1a.png',
      title: 'Game start',
      text: "The game map will be drawn based on your location when you launch the game. If it's now, then the game start will be the position marked on the map. ",
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: `Got it`,
      confirmButtonColor: '#4E3B74',
    })
  }

  getTour(tourId){
     return this.http.post(environment.baseUrl + 'tours/getTourById', {_id: tourId} ).subscribe((data: any) => {
      console.log(data)
      if(data.success){
        this.activeTour = data.data;
        
      } else{
        this.navigateBack();
      }
    });
  }

  scrollTo(id){
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  navigateBack(){
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.stripe = Stripe(environment.stripe);
    if(this.gameService.isPartnerGame){
       this.swiperContent = `<div class="swiper-slide text-center">
       <img src="./assets/images/walkthrough/3.png"  width="200" alt="">
       <h5 class="fi-text-bold">How can I play?</h5>
       <p>Set the number of players, click the checkout button, and you are ready to play.</p>
       </div>
       <div class="swiper-slide text-center">
         <img src="./assets/images/walkthrough/2a.png"  width="200" alt="">
         <h5 class="fi-text-bold mt-3">How it starts?</h5>
         <p>Gather your friends, go where you want to play, and start the game by entering the PIN code received after the game setup. </p>
       </div>
       <div class="swiper-slide text-center">
         <img src="./assets/images/walkthrough/5a.png"  width="200" alt="">
         <h5 class="fi-text-bold mt-3">How can others join?</h5>
         <p>You and your friends can join the game by a PIN or QR code you received after setting up the game. Everybody plays on his device. </p>
       </div>
       <div class="swiper-slide text-center">
         <img src="./assets/images/walkthrough/3a.png"  width="200" alt="">
         <h5 class="fi-text-bold mt-3">Where can I start?</h5>
         <p>You can play the game wherever you want. The optimal playground would be around your workplace or headquater..  </p>
       </div>`
    } else{
      this.swiperContent = `<div class="swiper-slide text-center">
      <img src="./assets/images/walkthrough/3.png"  width="200" alt="">
      <h5 class="fi-text-bold">How can I play?</h5>
      <p>Set the number of players, click the checkout button, and you are ready to play.</p>
      </div>
      <div class="swiper-slide text-center">
        <img src="./assets/images/walkthrough/2a.png"  width="200" alt="">
        <h5 class="fi-text-bold mt-3">How it starts?</h5>
        <p>Gather your friends, go where you want to play, and start the game by entering the PIN code received after the purchase. </p>
      </div>
      <div class="swiper-slide text-center">
        <img src="./assets/images/walkthrough/5a.png"  width="200" alt="">
        <h5 class="fi-text-bold mt-3">How can others join?</h5>
        <p>You and your friends can join the game by a PIN or QR code you received after purchase. Everybody plays on his device. </p>
      </div>
      <div class="swiper-slide text-center">
        <img src="./assets/images/walkthrough/3a.png"  width="200" alt="">
        <h5 class="fi-text-bold mt-3">Where can I start?</h5>
        <p>You can play the game wherever you want. The optimal playground would be urban areas with buildings like a city center or downtown.  </p>
      </div>
      <div class="swiper-slide text-center">
        <img src="./assets/images/walkthrough/2.png"  width="200" alt="">
        <h5 class="fi-text-bold mt-3">When do I need to buy the game?</h5>
        <p>We recommend you purchase the game just before you want to play.</p>
      </div>`
    }
  }

}
