import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-museums',
  templateUrl: './museums.component.html',
  styleUrls: ['./museums.component.scss']
})
export class MuseumsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
