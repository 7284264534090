<!-- End game -->
<div class="flinkit-full-bg overflow-auto pt-5 pb-5" *ngIf="activeGame"  [style.background-color]="bgColor">
  <div class="row mr-0 ml-0">

    <div class="col-12 d-flex align-items-center justify-content-center h-100">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center text-white">
          <img *ngIf="!activeGame.partner.isMuseum" [src]="activeGame.tourId.isCustomBranding ? activeGame.tourId.customBranding.cover : (activeGame.partner && activeGame.partner.customLogo ? activeGame && activeGame.partner.customLogo : './assets/images/logo_bp.png')" width="160" alt="">
          <img *ngIf="activeGame.partner.isMuseum" [src]="activeGame.partner.isMuseum ? activeGame.partner.customLogo : './assets/images/pb.png'" class="img-fluid-80">
        </div>
        <!-- End of Logo container -->
        <div class="text-center flex-fill mt-4" *ngIf="activeGame.tourId.isLeaderboard else noLeaderBlockModule">
          <div class="row mt-3">
            <div class="col-12">
              <h3 class="fi-text-bold text-white">{{locales.hallOfFame}}</h3>
            </div>
          </div>
         <!--  <div class="row mt-3">
            <div class="col-12">
              <div class="fi-segmented-control">
                <button class="fi-text-bold" [class]="pageConfig.activeTab === 'leaderboard' ? 'active': ''" (click)="setTab('leaderboard')" i18n>Leaderboard</button>
                <button class="fi-text-bold" [class]="pageConfig.activeTab === 'hof' ? 'active': ''" (click)="setTab('hof')" i18n>Hall of Fame</button>
              </div> 
            </div>
          </div> -->
          <div class="row mt-3">
            <div class="col-12">
              <div class="fi-result-row p-3 shadow d-flex align-items-center mb-2" *ngFor="let player of players; let i = index;">
                <div class="mr-3">
                  <div class="fi-map-marker-enemy bg-2">
                    <img [src]="emojiService.getEmojiByIndex(player.avatarIndex).icon" width="30" alt="">
                  </div>
                </div>
                <div class="text-left">
                  <h4 class="mb-0">{{i+1}}. {{player.name}}</h4>&nbsp;{{player.points}}&nbsp;<span i18n>points</span> 
                </div>
              
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button class="flinkint-btn white outline btn-block" *ngIf="!activeGame.partner" (click)="share()" i18n >
              Share with friends
            </button>
            <button class="flinkint-btn white btn-block mt-2 mb-3"  [style.color]="bgColor"  [style.border-color]="bgColor" [disabled]="loaders.refresh"  (click)="refresh()">
              <span *ngIf="!loaders.refresh else loaderBtnBlock" i18n>Refresh</span>
              <ng-template #loaderBtnBlock><i class="las la-circle-notch la-spin"></i></ng-template>
            </button>
            <a class="text-white mt-3" *ngIf="!activeGame.partner.redirectUrl else noRedirect" [routerLink]="['/']" i18n>Back to main</a>
            <ng-template #noRedirect>
              <a class="text-white mt-3" href="{{activeGame.partner.redirectUrl}}" i18n>Back to main</a>
            </ng-template>
            <div class="row text-center mt-2" *ngIf="activeGame && activeGame.partner && !activeGame.partner.isMuseum && activeGame.partner.isExclusiveBranded">
              <div class="col-12 text-center fi-text-bold">
                <img [src]="activeGame.tourId.isCustomBranding ? activeGame.tourId.customBranding.cover : ((activeGame.partner && activeGame.partner.customLogo) ? activeGame && activeGame.partner.customLogo : './assets/images/pb.png')"  width="90"alt="">
              </div>
            </div>
            <div class="row text-center mt-2" *ngIf="activeGame.partner.isMuseum">
              <div class="col-12 text-center fi-text-bold">
                <img src="./assets/images/pb.png"  width="90"alt="">
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>

</div>
<!-- End of End game -->

<ng-template #noLeaderBlockModule>
  <div class="text-center flex-fill mt-4">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="fi-text-bold text-white">{{locales.congrats}}</h3>
      </div>
    </div>
  </div>
</ng-template>