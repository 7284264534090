import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionModalComponent } from './questionModal.component';
import { FormsModule } from '@angular/forms';
import { SafePipe } from '../safeUrl.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [QuestionModalComponent, SafePipe],
  exports: [QuestionModalComponent]
})
export class QuestionModalModule { }
