
  <div class="d-md-flex align-items-center justify-content-center position-relative pt-7-rex pb-5-rex" style="background:url(./assets/images/museums/museum-bg.jpg); background-size: cover;">
    <a class="fl-logo-absolute" href="https://flinkit.io"><img src="./assets/images/logo_museums.png" alt=""></a>

  <div class="fl-desktop-container position-relative d-none d-md-block">
    <div class="inner shadow p-4 position-relative">

      <a href="https://www.producthunt.com/posts/flinkit-friends?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-flinkit-friends" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=291287&theme=light&period=daily" alt="Flinkit Friends - Story-driven outdoor adventure games for teams, and friends | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
      <h2 class="fi-text-bold mt-3">Kalandos művészeti élmények, <br> kreatív kulturális programok, <br> interaktív kiállítások,
        </h2>
     
     
      <div class="fi-text-bold mt-3">Innovatív művészeti élmény, mely szórakoztat,  <br>
        tudással gazdagít és kalandosan próbára tesz.
        </div>
 
<!--       <img src="./assets/images/watch.png" data-toggle="modal" data-target="#videoModal" class="watch-icon fl-hoverer" width="40" alt="">
 -->      <img src="./assets/images/museums/bg.png" class="phone-icon-2" alt="">
    </div>
    <div class="outer"></div>
    <div class="content">
      <h5 class="fi-text-bold mb-1">Kerülj kapcsolatba velünk</h5>
      <div class="d-flex fi-desktop-footer justify-content-center w-100 mb-3">
        <div class="mr-2"><a href="mailto:info@flinkit.io">info@flinkit.io</a></div>
        <!-- <div class="ml-2"><a href="https://calendly.com/flinkitwebinar/30min?month=2021-03" target="_blank">Book a call with us</a></div> -->
      </div>
      <div class="fi-desktop-footer">
        <a href="https://calendly.com/flinkitwebinar/30min?month=2021-03">Egyedi igényeid vannak? <br>  Foglalj egy időpontot velünk.</a>
      </div>
    
    </div>
  </div>
  <div class="d-md-none d-block fl-mobile-landing-header w-100 p-3">
    <h4 class="fi-text-bold fi-text-vivid mb-0">Innovatív művészeti élmény, kalandosan, múzumok számára.</h4>
   
    
  </div>
</div>
<div class="container-fluid pt-3 pt-md-5 fl-landing-gradient" >
  <div class="row pt-0 pt-md-5">
    <div class="col-md-8 offset-md-2 text-center">
      <h2 class="fi-text-bold mt-3">Mi a Flinkit?</h2>
      <p>Web alapú, mobilalkalmazás letöltését nem igénylő, mobilra optimalizált játékplatform, amelynek
        segítségével múzeumi intézmények könnyen, gyorsan, személyre szabott múzeumi tartalmat
        képesek játékos formában átadni a látogatóknak.
        Hozzájárulunk, hogy az adott kiállítással mind vizuálisan, mind pedig lexikálisan összefüggő
        tudás élményorientált módon átadható, izgalmasan elmélyíthető legyen a látogatók számára.
       </p>
    </div>
  </div>
</div>

<div class="container pt-md-5" >
  <div class="row d-flex align-items-center">
    <div class="col-md-6 pl-0 pr-0 d-flex justify-content-center">
      <img src="./assets/images/museums/m2.png" width="330" height="550" alt="">
    </div>
    <div class="col-md-6 pl-md-0 pr-md-0">
      <h3 class="fi-text-bold mt-3">Előnyei?</h3>
      <ul class="mt-3">
        <li>Interaktívvá és játékossá tud varázsolni tetszőleges tárlatvezetést és
          kiállítást, kicsiknek és nagyoknak egyaránt</li>
        <li>A tudás és információ rögzítésére összpontosít, nem veszi el a fókuszt
          a valódi műtárgyakról és információkról</li>
        <li>Egyaránt használható vezetett, valamint kötetlen program esetén</li>
        <li>Alkalmas több, szimultán játék és program létrehozására, tartalom,
          felhasználás, valamint életkor szegmentációk segítségével</li>
        <li>Nem igényel mobilalkalmazás letöltést, így könnyen és bármilyen
          eszközön hozzáférhető</li>
        <li>Tetszőleges okostelefonon és tableten működik</li>
        <li>A játékok tartalmi felépítése könnyen és gyorsan összeállítható és
          módosítható a játékhoz tartozó adminisztrátori felület segítségével</li>
      </ul>

    </div>
  </div>
</div>

<div class="container pt-md-5" >
  <div class="row d-flex align-items-center">
  
    <div class="col-md-6">
      <h3 class="fi-text-bold mt-3">Hogyan működik?</h3>
      <ul class="mt-3">
        <li>A látogató a múzeumi jegyének megvásárlásakor “Flinkit KalandART” belépőt is
          vált, mely egy előre generált QR kódot takar</li>
        <li>Ennek leolvasásával megnyílik a múzeum egyedi arculati elemeivel megjelenő
          nyitó oldal</li>
        <li>A műkincskereső expedíció, ismeretterjesztő játék útvonala a kiemelt, megjelölt
          műalkotásokhoz, helyszínekhez, látnivalókhoz vezeti el a látogatókat</li>
        <li>A látogató a kiállító térben található, a Flinkit! által biztosított QR kódokat olvassa
          be készüléke segítségével</li>
        <li>Ekkor egy az adott műtárgyhoz/helyiséghez kapcsolódó kérdés jelenik meg,
          amelyet helyes válasz esetén a rendszer pontokkal jutalmaz. Valamennyi
          információs felület, kérdés és válasz opció tetszőleges hosszúságú lehet,
          tetszőleges multimédia tartalommal kiegészítve</li>
        <li>Ezt követően a látogató a webalkalmazás múzeumot ábrázoló térképén, illetve a
          valós térben navigálva keresi a múzeumban található további látható, valamint
          láthatatlan QR kódokat, további értékes pontokért</li>
        <li>A látogatók valós időben látják a múzeumi térben mozgó egyéb
          látogatókat és azok pontszámait, valamint egy összesített ranglistát
          korábbi látogatók elért pontszámaival a mindenkori GDPR szabályok
          figyelembevételével</li>
          <li>A játék végeztével a játék újra nem felhasználható</li>
      </ul>

    </div>
    <div class="col-md-6 d-flex justify-content-center">
      <img src="./assets/images/museums/m1.png" width="370" height="550" alt="">
    </div>
  </div>
</div>

<div class="container-fluid mt-5">
  <div class="row">
    <div class="col-12 pl-0 pr-0">
      <div class="pattern-bg first">
      
        <img src="./assets/images/ver2/pattern-ext.png" class="patter-ext" alt="">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="fi-text-bold">Sokrétű felhasználás</h2>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6 col-12 d-flex fl-landing-image">
            <img src="./assets/images/ver2/team-circle.png" class="mr-4 mobile-img"  alt="">
          </div>
          <div class="col-md-5 col-12 mt-3 mt-md-0">
            <ul class="text-bold-xs">
              <li>Kalandos élményséták beltéren és kültéren egyaránt.</li>
              <li>Kiállítási információk átadása, tárlatvezetés</li>
              <li>(Mű)kincskereső kalandtúra, verseny</li>
              <li>Játékos ismeretterjesztő program</li>
              <li>Osztálykirándulások, csapatépítő programok
                </li>
                <li>Kiemelt műalkotások, látnivalók, helyszínek bemutatása,
                  felfedezése egyazon játékprogram keretében
                </li>
                <li>Edukatív jellegű, művészeti, kulturális témák élményalapú
                  bemutatása
                </li>
                <li>Intézményhez tartozó kert, park, város, település bemutatása
                </li>
                <li>Tematikus kiállítások, rendezvények egyedi bemutatása</li>
                <li>Korosztályok szerint bontott témák élményalapú bemutatása</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 pl-0 pr-0">
      <div class="pattern-bg plain">
        <img src="./assets/images/ver2/pattern-ext1.png" class="patter-ext green" alt="">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="fi-text-bold">Láss neki azonnal! </h2>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4 offset-md-2 text-center d-flex justify-content-center">
            <div>
              <img src="./assets/images/ver2/icon2.png" class="max-w160" alt="">
              <h5 class="mt-3 fi-text-bold">Adminisztrátori felület</h5>
              <p>A Flinkit által biztosított adminisztrátori felület segítségével a múzeum
                képes: létrehozni, módosítani, törölni játékokat, megtekinteni játék statisztikákat, megtekinteni és exportálni játékos adatokat további
                kapcsolatfelvétel, remarketing céljából
               
              </p>
            
            </div>
          </div>
          <div class="col-md-4 text-center d-flex justify-content-center">
            <div>
              <img src="./assets/images/ver2/icon.png" class="max-w160" alt="">
              <h5 class="mt-3 fi-text-bold">Egyszerű integráció</h5>
              <p>A játék integrációs igénye igen alacsony, ami a múzeum részéről a
                pálya/pályák kérdéssorainak összeállítását valamint adminisztrátori
                felületen való feltöltését, és a Flinkit! által biztosított QR kódok
                kihelyezését jelenti.
              </p>
             
            </div>
          </div>
        </div>
       <!--  <div class="row mt-5">
          <div class="col-md-4 offset-md-2 text-center d-flex justify-content-center">
            <div>
              <img src="./assets/images/ver2/icon6.png" class="max-w160" alt="">
              <h5 class="mt-3 fi-text-bold">1. lépés</h5>
              <p>Hozd létre saját Flinkit! fiókodat, állítsd össze a max. 4 fős csapatokat manuálisan, vagy a Flinkit! segítségével.
              </p>
              <a href="">Többet szeretnék tudni...</a>
            </div>
          </div>
          <div class="col-md-4 text-center d-flex justify-content-center">
            <div>
              <img src="./assets/images/ver2/icon7.png" class="max-w160" alt="">
              <h5 class="mt-3 fi-text-bold">1. lépés</h5>
              <p>Hozd létre saját Flinkit! fiókodat, állítsd össze a max. 4 fős csapatokat manuálisan, vagy a Flinkit! segítségével.
              </p>
              <a href="">Többet szeretnék tudni...</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 pl-0 pr-0">
      <div class="pattern-bg plain2">
        <img src="./assets/images/ver2/footer.png" class="patter-ext green" alt="">
        <div class="row">
          <div class="col-12 text-center">
            <img src="./assets/images/ver2/slogo4.png" width="120" alt="">
          </div>
          <div class="col-12 text-center mt-3">
            <a href="https://www.youtube.com/channel/UCV0vkUmvW8G1U3xmbrHHgLw" class="mr-3"><img src="./assets/images/ver2/slogo.png" width="40" alt=""></a>
            <a href="https://www.facebook.com/flinkitgame" class="mr-3"><img src="./assets/images/ver2/slogo1.png" width="40" alt=""></a>
            <a href="https://www.linkedin.com/company/flinkit-global"><img src="./assets/images/ver2/slogo2.png" width="40" alt=""></a>
          </div>
          <div class="col-6 offset-3 text-center mt-4">
            <small class="text-light-color">© Flinkit! 2020 All rights reserved.</small><br>
            <small class="text-light-color">When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information
              to help provide you with a better, faster and safer experience and for marketing purposes.</small>
          </div>

        </div>
        
      </div>
    </div>
  </div>
</div>

<div class="modal" id="videoModal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered " style="height: initial !important;">
      <div class="modal-content">
          <div class="modal-body">
            <iframe height="400" style="width:100%;" src="https://www.youtube.com/embed/L9otgka3xGE" title="Flinkit! Friends!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
  </div>
</div>