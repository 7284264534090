import { Injectable } from '@angular/core';
import Swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

constructor() { }

checkMobile(){
  var userAgent = navigator.userAgent || navigator.vendor;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
      return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
  }

  return "unknown";
}

dropSwal(title, text, icon){
  Swal.fire({
    icon,
    title,
    text,
    showDenyButton: false,
    showCancelButton: false,
    showConfirmButton: true,
    confirmButtonText: `Got it`,
    confirmButtonColor: '#4E3B74',
  })
}

checkDeviceCompatibility(){
  if(this.checkMobile() == "iOS" && navigator.userAgent.indexOf("Safari") === -1){
    this.dropSwal("Switch to Safari", "On iOS devices, please use Safari browser", "info");
    return false;
  } else if(this.checkMobile() == "Android" && navigator.userAgent.indexOf("Chrome") === -1){
    this.dropSwal("Switch to Chrome", "On Andrpid devices, please use Chrome browser", "info");
    return false; 
  } else{
    return true;
  }
}
}
