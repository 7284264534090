import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumGamepassComponent } from './museum-gamepass.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [MuseumGamepassComponent],
  imports: [
    CommonModule,
    RouterModule
  ], exports: [MuseumGamepassComponent]
})
export class MuseumGamepassModule { }
