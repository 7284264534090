<!-- Step 0 -->
<div class="flinkit-full-bg">
  <div class="row h-100 mr-0 ml-0">

    <div class="col-12 d-flex align-items-center justify-content-center h-100">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center text-white">
          <img src="./assets/images/success.png" width="100" alt="">
        </div>
        <!-- End of Logo container -->
        <div class="text-center flex-fill">
          <h4 class="text-white font-weight-bold" i18n >Payment successful!</h4>
          <div class="mt-3">
            <div class="fi-segmented-control">
              <button class="fi-text-bold" (click)="pageSettings.joinMode = 'pin'" [class]="pageSettings.joinMode === 'pin' ? 'active' : ''">PIN</button>
              <button class="fi-text-bold" (click)="pageSettings.joinMode = 'qr'"  [class]="pageSettings.joinMode === 'qr' ? 'active' : ''">QR</button>
            </div> 
          </div>
          <div *ngIf="pageSettings.joinMode === 'pin' else qrBlock">
            <div class="mt-1">
              <div class="text-white flinkit-text-small" i18n>Share this PIN code with your friends to join the game</div>
            </div>
            <div class="flinkit-pin-code-container d-inline-flex mt-3" >
              <div class="pin-input-container mr-2" >
                <input type="text"   [(ngModel)]="pinCode[0]"  placeholder="0" disabled>
              </div>
              <div class="pin-input-container mr-2" mask="0" >
                <input type="text" [(ngModel)]="pinCode[1]"   placeholder="0" disabled>
              </div>
              <div class="pin-input-container" mask="0">
                <input type="text" [(ngModel)]="pinCode[2]"  placeholder="0" disabled>
              </div>
            </div>
          </div>
          <ng-template #qrBlock>
            <div class="mt-1">
              <div class="text-white flinkit-text-small" i18n>Ask your friends to scan this code to join the game</div>
            </div>
            <div class="flinkit-pin-code-container d-inline-flex mt-3" >
              <qrcode [qrdata]="mobileAppService.getURL()+'en/pin;p='+pinCode" [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
          </ng-template>
         
          <div class="mt-3">
            <button class="flinkint-btn white outline btn-block" (click)="share()"  i18n>
              Share PIN
            </button>
            <button class="flinkint-btn white btn-block" (click)="joinGame()"  i18n>
              Join the game
            </button>
          </div>
          <div class="mt-3">
            <div class="text-white flinkit-text-small" i18n></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- End of Step 0 -->
