import { Injectable } from '@angular/core';
import Swal from "sweetalert2";
import { GameService } from './game.service';
import { LocaleService } from './locale.service';
import Noty from 'noty';

declare var detect;
@Injectable({
  providedIn: 'root'
})
export class LocationService {
  options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 10000
  };
  constructor(private gameService: GameService, private localeService: LocaleService) { }

  dropNoty(type, text, timeout?) {
    new Noty({
      type: type,
      text: text,
      killer: true,
      timeout
    }).show();
  }

  isValidBrowser(){

    if(detect){
      let ua = detect.parse(navigator.userAgent);
      
      let isSafari = ua.browser.family.indexOf('Safari') === -1 ? false : true;
      let isChrome = ua.browser.family.indexOf('Chrome') === -1 ? false : true;
      //navigator.userAgent.match(/SamsungBrowser/i)
      if(isChrome || isSafari){
        return true;
      } else{
        this.dropErrorBrowser();
        return false;
      }
    } else{
      let isChrome = navigator.userAgent.indexOf("Chrome") !== -1 ? true : false;
      let isSafari = navigator.userAgent.indexOf("Safari") !== -1 ? true : false;
      if(isChrome || isSafari){
        return true;
      } else{
        this.dropErrorBrowser();
        return false;
      }
    }
   

  
  }

  dropErrorBrowser(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/6a.png',
      title: this.localeService.getTrans('Not supported browser'),
      text: this.localeService.getTrans("The game only supports Chrome or Safari mobile browsers. "),
      showDenyButton: false,
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: this.localeService.getTrans(`Got it`),
      confirmButtonColor: '#4E3B74'
    }).then((result) => {
       this.isValidBrowser();
    });
  }

  getPosition(){
        if (navigator.geolocation) {
          return new Promise(
            (resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, this.options)
          )
        } else {
          return new Promise(
            resolve => resolve({})
          )
        }
  }

  dropError(){
    Swal.fire({
      imageUrl: '../assets/images/walkthrough/6a.png',
      title: 'Oops',
      text: "Please make sure, that location access is enabled in your mobile browser,",
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `How can I turn on?`,
      confirmButtonColor: '#4E3B74',
      cancelButtonText: 'Got it'
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.gameService.getMobileOperatingSystem() === 'iOS'){
          window.open('https://support.apple.com/en-us/HT207092','_blank');
        } else{
          window.open('https://support.google.com/chrome/answer/142065?co=GENIE.Platform%3DAndroid&hl=en','_blank');
        }
      } 
    });
  }

  

  


}
