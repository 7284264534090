<div id="museumBg1">
  <div class="overlay">
    <div class="container vh-100 p-3 d-flex justify-content-center align-items-center">
     
      <div class="row">
        <div class="col-12 text-center">
          <img src="./assets/images/success.png" width="150" alt="">
          <h5 class="text-yl fi-text-bold fw-bold mt-3">Thanks!</h5>
          <p>You will receive an email shortly, with the game activation link. Please check your spam folder also.</p>
        </div>
      
        <div class="col-12  text-center">
          <p class="pb-0 text-muted mb-0"><small class="text-muted">{{localeService.getTrans('Lépj kapcsolatba velünk:')}}</small></p>
          <p class="pb-2 text-yl text-muted"><small><a href="mailto:info@flinkit.io" class="text-yl text-muted">info@flinkit.io</a> | <a href="mailto:ugyfelszolgalat@flinkit.io" class="text-yl text-muted">ugyfelszolgalat@flinkit.io</a></small></p>
        </div>
        
       
      </div>
     
    </div>

  </div>
</div>

