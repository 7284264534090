import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient) { }

  log(partner, partnerName, type, amount){
    this.http.post(environment.baseUrl + 'logs/createLogToZapier', { partner, partnerName, type, amount }).subscribe((data: any) => { });
  }

}
