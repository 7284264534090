import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmojiServiceService {

  emojis = [{icon: './assets/images/emoji/girl.png', index: 0},
    {icon: './assets/images/emoji/girl1.png', index: 1},
    {icon: './assets/images/emoji/girl2.png', index: 2},
    {icon: './assets/images/emoji/boy.png', index: 3},
    {icon: './assets/images/emoji/boy2.png', index: 4},
    {icon: './assets/images/emoji/boy3.png', index: 5},
    {icon: './assets/images/emoji/cat.png', index: 6},
    {icon: './assets/images/emoji/dog.png', index: 7},
    {icon: './assets/images/emoji/ironman.png', index: 8},
    {icon: './assets/images/emoji/pug.png', index: 9},
    {icon: './assets/images/emoji/purpleman.png', index: 10},
    {icon: './assets/images/emoji/spiderman.png', index: 11},
    {icon: './assets/images/emoji/demon.png', index: 12},
    {icon: './assets/images/emoji/don.png', index: 13},
    {icon: './assets/images/emoji/dragon.png', index: 14},
    {icon: './assets/images/emoji/knight.png', index: 15},
    {icon: './assets/images/emoji/pirate.png', index: 16},
    {icon: './assets/images/emoji/princess.png', index: 17},
    {icon: './assets/images/emoji/samurai.png', index: 18}
  ]


  constructor() { }

  getEmojis(){
    return this.emojis;
  }

  getEmojiByIndex(index: number){
    return this.emojis[index];
  }
}
