<swiper [config]="{
  spaceBetween: 10,
  loop: true,
  initialSlide: 0,
  slidesPerView: 'auto',
  centeredSlides: true,
  pagination: { el: '.swiper-pagination', clickable: true }
}"  #usefulSwiper class="mt-3">
  <div class="swiper-wrapper" [innerHTML]="swiperContent">
   
  </div>
  <div class="swiper-pagination"></div>
</swiper>