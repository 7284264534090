<div class="flinkit-full-bg white" *ngIf="game">
  <div class="row mr-0 ml-0 fi-walkthrough-container pt-5 pb-5">
    <div class="col-12 d-flex align-items-center justify-content-center text-center">
      <div class="w-100">
        <!-- Logo container -->
        <div class="text-center" *ngIf="partner">
          <img [src]="game && game.tourId && game.tourId.isCustomBranding ? game.tourId.customBranding.cover : (game.partner && game.partner.customLogo ? game && game.partner.customLogo : './assets/images/museums/logo_b.png')" class="img-fluid-30" alt="">
         <!--  <img [src]="partner && partner.customLogo ? partner.customLogo : './assets/images/museums/logo_b.png'"
            class="img-fluid-80" alt=""> -->
        </div>
        <!-- End of Logo container -->
        <swiper #usefulSwiper [config]="swiperConfig" class="mt-5">
          <div class="swiper-wrapper align-items-center">
            <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/7a.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>Welcome</h5>
              <p>{{locales.walkthrough}}</p>
            </div>
            <!-- End of Swiper -->
             <!-- Swiper -->
             <div class="swiper-slide">
              <img src="./assets/images/walkthrough/2.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>The goal</h5>
              <p>{{locales.walkthroughText2}}</p>
            </div>
            <!-- End of Swiper -->
              <!-- Swiper -->
            <div class="swiper-slide" *ngIf="!game.tourId.isQR">
              <img src="./assets/images/walkthrough/8.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>Questions & markers</h5>
              <p>{{locales.questionsAndMarkers}}</p>
            </div>
            <!-- End of Swiper -->
               <!-- Swiper -->
               <div class="swiper-slide" *ngIf="game.tourId.isQR">
                <img src="./assets/images/walkthrough/11.png" alt="">
                <h5 class="fi-text-bold mt-3" i18n>Questions & markers</h5>
                <p>{{locales.questionsAndMarkers2}}</p>
              </div>
              <!-- End of Swiper -->
 
            <!-- Swiper -->
            <div class="swiper-slide">
              <img src="./assets/images/walkthrough/10.png" alt="">
              <h5 class="fi-text-bold mt-3" i18n>End of the game</h5>
              <p i18n>The game ends when you solve all the riddles or the time is up.</p>
              <button class="flinkint-btn primary btn-block mt-3"  [style.background-color]="bgColor" [style.border-color]="bgColor"   (click)="joinGame()" i18n>I'm ready to start</button>
            </div>
            <!-- End of Swiper -->
          </div>
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</div>