import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameComponent } from './game.component';
import { HttpClientModule } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import {NgbModal, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FormsModule } from '@angular/forms';
import { SafePipe } from '../components/safeUrl.pipe';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxUsefulSwiperModule,
    LeafletModule,
    FormsModule,
    ZXingScannerModule,
    NgbModalModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZXJjc2V5cGV0ZXIxIiwiYSI6ImNraXd4M2ZqdjFzYXcycXNjZDB4ejc2aWwifQ.TlJgNRdtGQizrgVtAYDFeg'
    })
   
  ],
  declarations: [GameComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class GameModule { }
