import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutComponent } from './checkout.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { WalkthroughModule } from '../components/walkthrough/walkthrough.module';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxSliderModule,
    FormsModule,
    WalkthroughModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZXJjc2V5cGV0ZXIxIiwiYSI6ImNraXd4M2ZqdjFzYXcycXNjZDB4ejc2aWwifQ.TlJgNRdtGQizrgVtAYDFeg'
    })
  ],
  declarations: [CheckoutComponent],
  exports: [CheckoutComponent]
})
export class CheckoutModule { }
