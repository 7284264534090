import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from '@angular/router';
import { EmojiServiceService } from '../services/emoji-service.service';
import Swal from "sweetalert2";
import * as _ from 'lodash';
import { GameService } from '../services/game.service';
import { MobileAppService } from '../services/mobile-app.service';

declare var $;

$.fn.extend({ 
  rotaterator: function(options) {

      var defaults = {
          fadeSpeed: 500,
          pauseSpeed: 100,
  child:null
      };
       
      var options = $.extend(defaults, options);
   
      return this.each(function() {
            var o =options;
            var obj = $(this);                
            var items = $(obj.children(), obj);
    items.each(function() {$(this).hide();})
    if(!o.child){var next = $(obj).children(':first');
    }else{var next = o.child;
    }
    $(next).fadeIn(o.fadeSpeed, function() {
      $(next).delay(o.pauseSpeed).fadeOut(o.fadeSpeed, function() {
        var next = $(this).next();
        if (next.length == 0){
            next = $(obj).children(':first');
        }
        $(obj).rotaterator({child : next, fadeSpeed : o.fadeSpeed, pauseSpeed : o.pauseSpeed});
      })
    });
      });
  }
});

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  pageSettings = {
    partnerId: null
  }
  activeTours = [];
  hofs = [];
  swiperContent = `<div class="swiper-slide text-center">
  <img src="./assets/images/walkthrough/1a.png"  width="200" alt="">
  <h5 class="fi-text-bold mt-3">What is it?</h5>
  <p>Flinkit is a new real world adventure game packed with codes to be cracked, clues to be uncovered, and puzzles to be solved in and outside. Fast, fun and easy to get going.</p>
</div>
<div class="swiper-slide text-center">
  <img src="./assets/images/walkthrough/3.png"  width="200" alt="">
  <h5 class="fi-text-bold mt-3">Pick and unlock your game</h5>
  <p>Meet your team in any urban area, unlock the game with credit card and you are ready to play. Quick and easy. </p>
</div>
<div class="swiper-slide text-center">
  <img src="./assets/images/walkthrough/2a.png"  width="200" alt="">
  <h5 class="fi-text-bold mt-3">Start the game</h5>
  <p>After purchasing it, you and your friends can join the game by a PIN or QR code. Everybody plays on his/her device. </p>
</div>
<div class="swiper-slide text-center">
              <img src="./../assets/images/walkthrough/2.png" width="200" alt="">
              <h5 class="fi-text-bold mt-3" i18n>How to win?</h5>
              <p i18n>Pick up all story points and solve riddles on the map, uncover the clues whilst staying in the battle circle.</p>
            </div>
           
           
               
            
                
<div class="swiper-slide text-center">
  <img src="./assets/images/walkthrough/1.png"  width="200" alt="">
  <h5 class="fi-text-bold mt-3">End of the game</h5>
  <p>Pick your game above and get started! Ready for your mission? Select a game above.</p>

</div>`;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, public emojiService: EmojiServiceService, public gameService: GameService, private mobileAppService: MobileAppService) {
    if (this.route.snapshot.paramMap.get('pId')) {
      this.pageSettings.partnerId = this.route.snapshot.paramMap.get('pId')
    } 
  }

  selectTour(tour){
    console.log(tour)
    if(tour.disabled){
      Swal.fire({
        icon: 'info',
        title: 'Not available',
        text: 'This story will be available soon.',
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `Got it`,
        confirmButtonColor: '#4E3B74',
      })
    } else{
      this.router.navigate(['/checkout', {t: tour._id}])
    }
  }

  contactUs(){
    Swal.fire({
      icon: 'info',
      title: 'Contact us',
      text: 'Bringing the whole company? Or need special terms? Get in touch now!',
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `Via email`,
      denyButtonText: `Schedule a call`,
      confirmButtonColor: '#4E3B74',
      denyButtonColor: '#4E3B74',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href="mailto:info@flinkit.io";
      } else if (result.isDenied) {
        window.open('https://calendly.com/flinkitwebinar/30min', '_blank');
      }
    })
  }
  mobileCheck() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
     return true;
    }else{
      // false for not mobile device
      return false;
    }
  };

  joinGame(){
    this.router.navigate(['/pin'])
  }

  jumpToGame(tourId){
    if(this.mobileCheck()){
      this.selectTour(_.find(this.activeTours, (x)=>{
        return x._id === tourId;
      }))
    } else{
      Swal.fire({
        imageUrl: './assets/images/frame.png',
        imageWidth: 180,
        title: 'Switch to mobile',
        text: 'Flinkit! Outdoor! works only in mobile browsers. Please switch to a smartphone to access the game.',
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `Got it`,
        confirmButtonColor: '#4E3B74',
        cancelButtonText: 'Back'
      })
    }
    
  }

  scrollTo(id){
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  getHof(){
    return this.http.post(environment.baseUrl + 'games/getHof', {} ).subscribe((data: any) => {
      console.log(data)
      this.hofs = data.data;
    }); 
  }

  getTours(){
     return this.http.post(environment.baseUrl + 'tours/getTours', {} ).subscribe((data: any) => {
      console.log(data)
      if(data.success){
        this.activeTours = data.data;
      }
    }); 
  }
  getPartnerTours(){
    return this.http.post(environment.baseUrl + 'tours/getPartnerTours', {partner: this.route.snapshot.paramMap.get('u')} ).subscribe((data: any) => {
     console.log(data)
     if(data.success){
       this.activeTours = _.map(data.data, (m)=>{
        return _.merge(m, {cover: this.gameService.partnerDetails.game_pic_url})
       });
       console.log(data)
     }
   }); 
 }

  share(){
    if (navigator.share) {
      navigator.share({
        title: 'Flinkit! Outdoor! | Story-driven outdoor adventure games with family, friends and colleagues.',
        url: this.mobileAppService.getURL() + 'en/'
      }).then(() => {
        Swal.fire({
          imageUrl: '../assets/images/5.png',
          title: 'Coupon code',
          text: 'You coupon code is: FRIENDS5',
          showDenyButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: `Got it`,
          confirmButtonColor: '#4E3B74',
          cancelButtonText: 'Back'
        })
      })
      .catch(console.error);
    } else {
      console.log('Share is not supported')
    }
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('u')) {
      this.gameService.partner = this.route.snapshot.paramMap.get('u');
      this.gameService.isPartnerGame = true;
      return this.http.post(environment.baseUrl + 'partners/getPartner', {_id: this.route.snapshot.paramMap.get('u')} ).subscribe((data: any) => {
        console.log(data)
        if(data.success){
          this.gameService.partnerDetails = data.data;
          this.getPartnerTours();
        }
      }); 

     
     
    } else{
      this.getTours();
    }
    
    
    
  $(document).ready(function() {
    $('#rotate').rotaterator({fadeSpeed:500, pauseSpeed:1500});
});
    
  }

}
