import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EndScreenComponent } from './end-screen.component';




@NgModule({
  declarations: [EndScreenComponent],
  imports: [
    CommonModule
  ],exports: [EndScreenComponent]
})
export class EndScreenModule { }
