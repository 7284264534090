<div id="museumBg1">
  <div class="overlay">
    <div class="container p-3">
      <!-- Header -->
      <div class="row">
        <div class="col-6">
          <img *ngIf="!pageConfig.isAirbnb" [src]="(partner && partner.isDigitalMuseum) ? partner.customLogo : '../../assets/images/logo_museums.png'" width="120" alt="">
          <!-- <img src="../../assets/images/logo_museums.png" width="120" alt=""> -->
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-light fl-text-bold"  id="dropdownBasic1" ngbDropdownToggle><img src="https://flinkitother.blob.core.windows.net/images/uk.png" width="40"></button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="switchLanguage('hu')" class="d-flex align-items-center"><img src="https://flinkitother.blob.core.windows.net/images/hu.png" class="mr-1" width="20">Hungarian</button>
              <button ngbDropdownItem (click)="switchLanguage('en')"><img src="https://flinkitother.blob.core.windows.net/images/uk.png" class="mr-1" width="20">English</button>
              <button ngbDropdownItem (click)="switchLanguage('de')"><img src="https://flinkitother.blob.core.windows.net/images/de.png" class="mr-1" width="20">German</button>
              <button ngbDropdownItem (click)="switchLanguage('fr')"><img src="https://flinkitother.blob.core.windows.net/images/fr.png" class="mr-1" width="20">French</button>
            </div>
          </div>
       
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <h1 class="text-white fs-stroke fs-bold fi-text-black">{{localeService.getTrans('Felfedezős kalandjáték a mobilodon itt és most.')}}</h1>
        </div>
      </div>
      
     
      <div class="paymentBlock">
        <div>
          <i class="las la-circle-notch la-4x text-white la-spin ml-1"></i>
        <div class="text-white fi-text-08">{{localeService.getTrans('Loading game')}}</div>
        </div>
        
      </div>
    </div>

  </div>
</div>





<!-- <div id="museumBg" class="d-flex align-items-center" *ngIf="partner">
  <div>
  <div class="d-flex justify-content-center align-items-center text-center pt-5 w-100">
    <img [src]="(partner && partner.customLogo) ? partner.customLogo : './assets/images/museums/logo_b.png'" class="img-fluid-80" alt="">
  </div>
  
  <div class="bottom-button-container mt-5 d-flex justify-content-center">
    <div class="w-100">
      <div class="row mt-3 mb-3">
        <div class="col-12 text-center">
          <div class="fi-text-black mb-1">{{localeService.getTrans('Switch language')}}</div>
       
        </div>
        <div class="col-6 offset-3 text-center mt-2 mb-2">
          <img src="https://flinkitother.blob.core.windows.net/images/hu.png" (click)="switchLanguage('hu')" class="mr-3" width="40">
          <img src="https://flinkitother.blob.core.windows.net/images/uk.png" (click)="switchLanguage('en')" class="mr-3" width="40">
          <img src="https://flinkitother.blob.core.windows.net/images/de.png" (click)="switchLanguage('de')" width="40">
        
        </div>
      </div>
      <button class="flinkint-btn primary shadow pr-5 pl-5" [style.border-color]="partner ? partner.color : null" [style.backgroundColor]="partner ? partner.color : null" *ngIf="!pageConfig.loaders.validateGame else gameStartLoadingBlock" [disabled]="pageConfig.loaders.validateGame" (click)="scanQR()" i18n>Scan QR code</button>
       <ng-template #gameStartLoadingBlock>
        <button class="flinkint-btn primary shadow pr-5 pl-5 m-auto" [style.border-color]="partner ? partner.color : null" [style.backgroundColor]="partner ? partner.color : null" [disabled]="true"><span i18n>Loading game</span><i class="las la-circle-notch la-spin ml-1"></i></button>
      </ng-template>
      <button class="flinkint-btn primary shadow pr-5 pl-5 mt-2" *ngIf="!partner.isDigitalMuseum" [style.border-color]="partner ? partner.color : null" [style.backgroundColor]="partner ? partner.color : null"  [disabled]="pageConfig.loaders.validateGame" (click)="navigateToPurchase()" i18n>{{localeService.getTrans('Available games')}}</button>

      <div>
        <img class="mt-3" *ngIf="!partner.isDigitalMuseum" src="../../assets/images/pbe.png" width="80" alt="">
        <p class="p-2 text-muted"><small>{{localeService.getTrans('The game only supports Chrome or Safari mobile browsers. ')}}</small></p>

      </div>
    </div>
  </div>
</div>
</div>  -->
