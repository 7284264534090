import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../services/utils.service';
import Swal from 'sweetalert2';
import { EmojiServiceService } from '../services/emoji-service.service';
import { LocaleService } from '../services/locale.service';
import * as _ from 'lodash';
import { SwiperComponent } from 'ngx-useful-swiper';
import { MobileAppService } from '../services/mobile-app.service';
import Noty from 'noty';
import { LogService } from '../services/log.service';
declare var Stripe;

@Component({
  selector: 'app-tourist',
  templateUrl: './tourist.component.html',
  styleUrls: ['./tourist.component.scss']
})
export class TouristComponent implements OnInit {

  @ViewChild('usefulSwiper',{static: true}) usefulSwiper: SwiperComponent;
  partner;
  stripe;
  partnerGames = [{
    name: 'Beat Sherlock in the Castle',
    description: 'A super fun, 90 minute long scavanger at one of the most iconic part of Budapest.',
    price: 10,
    _id: '640214ec3b44de66a0fd4b33',
  }];
  loaders = false;
  selectedLanguage;
  selectedTour;
  checkoutObj = {
    tour: null,
    isAirbnb: true,
    numberOfPlayers: 2,
  };
  iconLocale = 'uk';
  swiperConfig = {
    loop: false,
    slidesPerView: 'auto',
    centeredSlides: true,
    autoplay: {
      delay: 4000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private logService: LogService,
    private utilsService: UtilsService,
    public localeService: LocaleService,
    public emojiService: EmojiServiceService,
    private mobileAppService: MobileAppService
  ) {
    this.logService.log('642272236c9780c359cf109b', 'Airbnb', 0, 0);
  }

  switchLanguage(locale){
    let localeString = '/'+locale+'/';
    let redirectString = this.mobileAppService.getURL()+locale+'/' + window.location.href.substr(window.location.href.indexOf('museum-purchase'),window.location.href.length);
    window.location.href = redirectString;
  }

  

  changePlayers(direction: boolean){
    if(direction){
      this.checkoutObj.numberOfPlayers++;
    } else{
      if(this.checkoutObj.numberOfPlayers > 2){
        this.checkoutObj.numberOfPlayers--;
      }
    }
  }

  changedPlayerNumber(){
    if(this.checkoutObj.numberOfPlayers < 2){
      this.checkoutObj.numberOfPlayers = 2;
    }
  }


  selectTour(tour){
    this.selectedTour = tour;
    Swal.fire({
      imageUrl: '../../assets/images/v3/stripe.png',
      title: 'Checkout for ' + this.checkoutObj.numberOfPlayers + ' players',
      text: "Are you sure you want to purchase this game for "+ this.checkoutObj.numberOfPlayers +" players?",
      showDenyButton: false,
      position: 'center',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Back',
      confirmButtonText: 'Checkout',
      confirmButtonColor: '#644E9C'
    }).then((result) => {
      
      if (result.isConfirmed) {
        console.log(this.checkoutObj)
        if(this.checkoutObj.numberOfPlayers>=1){
          this.loaders = true;
          this.logService.log('642272236c9780c359cf109b', 'Airbnb', 1, 0);

          this.checkout(tour);
        }
        else{
    
          new Noty({
            type:'warning',
            text: 'Number of players needs to be at least 1',
            killer: true,
            timeout: 3000
          }).show()
        }
      }
    })
  }


  checkout(tour){
   
      this.checkoutObj.tour = this.selectedTour._id;
      this.checkoutObj.isAirbnb = true;
      return this.http.post(environment.baseUrl + 'payments/checkoutSessionDirect', {checkoutObj: _.merge(this.checkoutObj, {limit: this.checkoutObj.numberOfPlayers})} ).subscribe((data: any) => {
        if(data){
          this.stripe.redirectToCheckout({ sessionId: data.data.id });
        } else{
          this.loaders = false;
        }
      });
    
   
  }

  detectLocale(){
    if(window.location.href.indexOf('/hu/') !== -1){
      this.localeService.locale = 'hu';
      this.iconLocale = 'hu';
    } else if(window.location.href.indexOf('/de/') !== -1){
      this.localeService.locale = 'de';
      this.iconLocale = 'de';
    } else if(window.location.href.indexOf('/fr/') !== -1){
      this.localeService.locale = 'fr';
      this.iconLocale = 'fr';
    } else{
      this.localeService.locale = 'en';
      this.iconLocale = 'uk';
    }
  }

  ngOnInit() {
    this.stripe = Stripe(environment.stripe);
   
    this.detectLocale();
  }

}
