import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../services/locale.service';

@Component({
  selector: 'app-touristSuccess',
  templateUrl: './touristSuccess.component.html',
  styleUrls: ['./touristSuccess.component.scss']
})
export class TouristSuccessComponent implements OnInit {

  constructor(public localeService: LocaleService) { }

  ngOnInit() {
  }

}
