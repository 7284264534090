<!-- End game -->
<div class="flinkit-full-bg overflow-auto pt-5 pb-5">
  <div class="row mr-0 ml-0">

    <div class="col-12 d-flex align-items-center justify-content-center h-100">
      <div class="w-100">
       
        <div class="text-center flex-fill mt-4">
          <div class="row mt-3">
            <div class="col-12">
              <img src="../../assets/images/logo_white.png" width="120" alt="">
              <h3 class="fi-text-bold text-white mt-5">Welcome to a Flinkit game! <br><br> Please contact the program facilitator about how to start the game.</h3>
            </div>
          </div>
         <!--  <div class="row mt-3">
            <div class="col-12">
              <div class="fi-segmented-control">
                <button class="fi-text-bold" [class]="pageConfig.activeTab === 'leaderboard' ? 'active': ''" (click)="setTab('leaderboard')" i18n>Leaderboard</button>
                <button class="fi-text-bold" [class]="pageConfig.activeTab === 'hof' ? 'active': ''" (click)="setTab('hof')" i18n>Hall of Fame</button>
              </div> 
            </div>
          </div> -->
      
        
        </div>
      </div>
    </div>
  </div>

</div>
<!-- End of End game -->
