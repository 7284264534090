import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuseumWalkthroughComponent } from './museum-walkthrough.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    NgxUsefulSwiperModule,
    RouterModule,
    HttpClientModule
  ],
  declarations: [MuseumWalkthroughComponent],
  exports: [MuseumWalkthroughComponent]
})
export class MuseumWalkthroughModule { }
